//@ts-nocheck
import { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { useLocation } from "react-router-dom";
import { pdfjs } from "react-pdf";
import axios from "axios";
import MainDashboard from "../components/dashboards/main_dashboard";
import NotoSans from "../assets/fonts/Noto_Sans/static/NotoSans-Regular.ttf";
import NotoSansBold from "../assets/fonts/Noto_Sans/static/NotoSans-Bold.ttf";
import NotoSansTC from "../assets/fonts/Noto_Sans_TC/static/NotoSansTC-Regular.ttf";
import NotoSansBoldTC from "../assets/fonts/Noto_Sans_TC/static/NotoSansTC-Bold.ttf";
import NotoSansDevanagari from "../assets/fonts/Noto_Sans_Devanagari/static/NotoSansDevanagari-Regular.ttf";
import NotoSansDevanagariBold from "../assets/fonts/Noto_Sans_Devanagari/static/NotoSansDevanagari-Bold.ttf";
import { useLanguage } from "../context/Language";

// Register a font (optional, but recommended for better styling)
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// Main component
const ResourceItem = () => {
  const location = useLocation();
  const [translatedData, setTranslatedData] = useState(null);
  const [targetLanguage, setTargetLanguage] = useState(
    localStorage.getItem("lang") || "en"
  ); // Default to English
  const { selectedLanguage } = useLanguage();

  const getFontRegisterObj = () => {
    let fontObj = {
      family: "NotoSans",
      fonts: [{ src: NotoSansTC }, { src: NotoSansBoldTC, fontWeight: "bold" }],
      format: "truetype",
    };
    const lang = localStorage.getItem("lang");
    if (lang && lang == "hi") {
      fontObj["family"] = "NotoSansDevanagari";
      fontObj["fonts"] = [
        { src: NotoSansDevanagari },
        { src: NotoSansDevanagariBold, fontWeight: "bold" },
      ];
    }
    return fontObj;
  };

  Font.register(getFontRegisterObj());

  const sentfontFamily = () => {
    let page = {
      flexDirection: "column",
      backgroundColor: "#ffffff",
      padding: 30,
      fontFamily: "NotoSans",
    };
    const lang = localStorage.getItem("lang");
    if (lang && lang == "hi") {
      page["fontFamily"] = "NotoSansDevanagari";
    }
    return page;
  };

  // Define styles for your PDF
  const styles = StyleSheet.create({
    page: sentfontFamily(),
    section: {
      margin: 10,
      padding: 10,
    },
    title: {
      fontSize: 24,
      marginBottom: 10,
    },
    subtitle: {
      fontSize: 18,
      marginTop: 10,
      marginBottom: 5,
    },
    text: {
      fontSize: 12,
      lineHeight: 1.5,
      marginBottom: 10,
    },
    list: {
      marginLeft: 20,
    },
    image: {
      width: 200,
      height: 200,
      marginVertical: 15,
    },
    source: {
      fontSize: 10,
      marginTop: 5,
    },
  });

  // Function to translate text using Google Translate API
  const translateText = async (text) => {
    try {
      const response = await axios.post(
        `https://translation.googleapis.com/language/translate/v2?key=AIzaSyA9e3Xnl0AAZfWfKaXAM9Vh0lWqA_a_7lo`,
        {
          q: text,
          target: localStorage.getItem("lang"),
        }
      );
      return response.data.data.translations[0].translatedText;
    } catch (error) {
      console.error("Translation error:", error);
      return text; // Return original text if translation fails
    }
  };

  // Create a component that will render the PDF content
  const AlmondDocument = ({ data }) => {
    const imgSrc = data.img;
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.title}>{data?.name}</Text>
            <Image style={styles.image} src={imgSrc} />
            <Text style={styles.source}>{data?.src}</Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.subtitle}>{data?.watch}</Text>
            <View style={styles.list}>
              <Text style={styles.text}>{data?.firstlist}</Text>
              <Text style={styles.text}>{data?.secondlist}</Text>
              <Text style={styles.text}>{data?.thirdlist}</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.text}>{data?.text}</Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.text}>{data?.textTwo}</Text>
          </View>
        </Page>
      </Document>
    );
  };

  // Access the passed object from location.state
  const myObject = location.state || {};

  useEffect(() => {
    const translateContent = async () => {
      const translated = {};
      for (const [key, value] of Object.entries(myObject)) {
        if (typeof value === "string" && key !== "img") {
          // Exclude 'img' from translation
          translated[key] = await translateText(value);
        } else {
          translated[key] = value;
        }
      }
      setTranslatedData(translated);
    };

    translateContent();
  }, [myObject, selectedLanguage]);

  if (!translatedData) {
    return (
      <MainDashboard>
        <div className="flex justify-center">
          <div
            className="
          inline-flex items-center px-4 py-2 rounded-md text-sm font-medium
          bg-gray-300 text-gray-500 cursor-not-allowed
          "
          >
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Translating content...
          </div>
        </div>
      </MainDashboard>
    );
  }

  return (
    <MainDashboard>
      <div className="flex justify-center h-screen items-center">
        <PDFDownloadLink
          document={<AlmondDocument data={translatedData} />}
          fileName={`${myObject?.name}.pdf`}
        >
          {({ blob, url, loading, error }) => (
            <button
              disabled={loading}
              className={`
            inline-flex items-center px-4 py-2 rounded-md text-sm font-medium transition-colors
            ${
              loading
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            }
          `}
            >
              {loading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Generating PDF...
                </>
              ) : (
                <>
                  <svg
                    className="mr-2 h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                  Download {myObject?.name} PDF
                </>
              )}
            </button>
          )}
        </PDFDownloadLink>
      </div>
    </MainDashboard>
  );
};

export default ResourceItem;
