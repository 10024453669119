import Gauge from "../../components/Gauge";
import BreadHeader from "../../components/breadheader";
import MainDashboard from "../../components/dashboards/main_dashboard";
import EngagementTable from "../../components/tables/engagement";
import {
  axiosPrivateRiskScore,
  axiosProfitablity,
} from "../../api/axiosPrivate";
import { useQuery } from "@tanstack/react-query";

import { useEffect, useState } from "react";
import Select from "react-select";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
import { Line } from "react-chartjs-2";
import EngagmentLivestock from "../../components/tables/EngagmentLivestock";
import { replaceUnderscores } from "../../utils";
import { useTranslation } from "react-i18next";
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
};

// const labels = ["Week 1", "Week 2", "Week 3", "Week 4"];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: "Onion",
//       data: labels.map(() => Math.floor(Math.random() * 100)),
//       borderColor: "rgb(255, 99, 132)",
//       backgroundColor: "rgba(255, 99, 132, 0.5)",
//     },
//     {
//       label: "Potato",
//       data: labels.map(() => Math.floor(Math.random() * 100)),
//       borderColor: "rgb(53, 162, 235)",
//       backgroundColor: "rgba(53, 162, 235, 0.5)",
//     },
//   ],
// };

export default function Engagement() {
  const storedUserId = localStorage.getItem("tokenMsal");
  const [scoreList, setScore] = useState<any>();
  const [liveStockScores, setLiveStockScore] = useState<any>();
  const [activeModule, setActiveModule] = useState<any>("crops");
  const { t } = useTranslation("score");
  const { refetch } = useQuery(["userDetails"], () => {
    const URL = `/v1/location_scores/${storedUserId}/scores`;
    // return axiosPrivateRiskScore<ScoreData>({ method: "GET", url: URL })
    return axiosPrivateRiskScore<any>({ method: "GET", url: URL })
      .then((data) => {
        setScore(data?.data);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  });


  async function getLivestockScores() {
    const LIVESTOCK_URL = `/user-history/read/${storedUserId}`;
    axiosProfitablity<any>({ method: "GET", url: LIVESTOCK_URL })
      .then((data) => {
        setLiveStockScore(data?.data);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }

  useEffect(() => {
    getLivestockScores();
  }, []);
  

  const getAverageRisk = () => {
    // Calculate the average composite_total_score
    const totalScores = scoreList?.scores.reduce(
      (acc, score) => acc + score.composite_total_risk,
      0
    );

    const averageScore = totalScores
      ? totalScores / (scoreList?.scores.length ?? 1)
      : 0;

    return averageScore;
  };
  const getAverageRiskLivestock = () => {
    // Calculate the average composite_total_score
    const totalScores = liveStockScores?.scores.reduce(
      (acc, score) => acc + score.composite_total_risk,
      0
    );

    const averageScore = totalScores
      ? totalScores / (liveStockScores?.scores.length ?? 1)
      : 0;

    return averageScore;
  };

  const uniqueCrops = [
    ...new Set(scoreList?.scores.map((score) => score.crop)),
  ];
  const uniqueLivestock = [
    ...new Set(
      liveStockScores?.scores.map((score) =>
        replaceUnderscores(score.livestock_type)
      )
    ),
  ];
  // const cropsSearched = [...new Set(scoreList)]
  let cropCount = {};

  // Iterate through the array and update the count for each crop
  scoreList?.scores?.forEach((entry) => {
    let crop = entry.crop;
    cropCount[crop] = (cropCount[crop] || 0) + 1;
  });

  let liveStockCount = {};

  // Iterate through the array and update the count for each crop
  liveStockScores?.scores?.forEach((entry) => {
    let livestock = entry.livestock_type;
    liveStockCount[livestock] = (liveStockCount[livestock] || 0) + 1;
  });

  // console.log(scoreList?.scores, "crop count");

  // let groupedData = scoreList?.scores?.reduce((acc, entry) => {
  //   // Extract week and year from search_date
  //   let weekYear = new Date(entry.search_date).toISOString().slice(0, 10);

  //   // Initialize array for the week if not present
  //   acc[weekYear] = acc[weekYear] || [];

  //   // Add entry to the week
  //   acc[weekYear].push(entry);

  //   return acc;
  // }, {});
  // let searchesPerWeekArray = Array.from({ length: 4 }, () => 0);

  // Iterate through the weeks and update the array
  // Object.keys(groupedData).forEach((week, index) => {
  //   let totalSearches = groupedData[week].length;

  //   // Update the array based on the position of the week in the month
  //   searchesPerWeekArray[index] = totalSearches;
  // });

  // console.log(groupedData, "groupdata");
  // console.log(searchesPerWeekArray, "fix");
  const optionsSelect = [
    { value: "crops", label: "Crops" },
    { value: "livestock", label: "Livestock" },
  ];
  const dataPie = {
    labels: uniqueCrops,
    datasets: [
      {
        label: "# of Searches",
        data: Object.values(cropCount),
        backgroundColor: [
          "rgb(51, 143, 217)",
          "rgb(244, 145, 5)",
          "rgb(246, 120, 78)",
          "rgb(143, 202, 249)",
          "rgb(255, 83, 83)",
          "rgb(0, 141, 6)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 0)",
          "rgba(202, 67, 142,0)",
          "rgba(54, 162, 235, 0)",
          "rgba(202, 67, 142,0)",
          "rgba(54, 162, 235, 0)",
          "rgba(202, 67, 142,0)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const dataPieLivestock = {
    labels: uniqueLivestock,
    datasets: [
      {
        label: "# of Searches",
        data: Object.values(liveStockCount),
        backgroundColor: [
          "rgb(248 195 58)",
          "rgb(44, 160, 73)",
          "rgb(246, 120, 78)",
          "rgb(246, 67, 92)",
          "rgba(20, 108, 148, 1)",
          "rgba(167, 93, 93, 1)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 0)",
          "rgba(202, 67, 142,0)",
          "rgba(54, 162, 235, 0)",
          "rgba(202, 67, 142,0)",
          "rgba(54, 162, 235, 0)",
          "rgba(202, 67, 142,0)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const data = {
    labels: [],
    datasets: [
      {
        label: t("Number of searches"),
        data: [],
        fill: true,
        backgroundColor: "rgb(44, 160, 73)",
        borderColor: "rgb(44, 160, 73)",
      },
    ],
  };
  const linedata = {
    labels: [],
    datasets: [
      {
        label: t("Number of searches"),
        data: [],
        fill: true,
        backgroundColor: "rgb(44, 160, 73)",
        borderColor: "rgb(44, 160, 73)",
      },
    ],
  };

  // Function to get the week number from a given date
  const getWeekNumber = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    // @ts-ignore
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  };

  // Count occurrences of each week in the array
  const result = scoreList?.scores?.reduce((acc, obj) => {
    const weekNumber = getWeekNumber(obj.search_date);
    const weekKey = `week ${weekNumber}`;
    acc[weekKey] = (acc[weekKey] || 0) + 1;

    return acc;
  }, {});
  // Fill in weeks with 0 occurrences
  if (result) {
    for (let i = 1; i <= 4; i++) {
      const weekKey = `week ${i}`;
      result[weekKey] = result[weekKey] || 0;
      data.labels.push(weekKey);
      data.datasets[0].data.push(result[weekKey] || 0);
    }
  }
  const resultline = liveStockScores?.scores?.reduce((acc, obj) => {
    const weekNumber = getWeekNumber(obj.search_date);

    const weekKey = `week ${weekNumber}`;
    acc[weekKey] = (acc[weekKey] || 0) + 1;

    return acc;
  }, {});
  // Fill in weeks with 0 occurrences
  if (resultline) {
    for (let i = 1; i <= 4; i++) {
      const weekKey = `week ${i}`;
      resultline[weekKey] = resultline[weekKey] || 0;
      linedata.labels.push(weekKey);
      linedata.datasets[0].data.push(resultline[weekKey] || 0);
    }
  }

  return (
    <MainDashboard>
      <div>
        <div className="flex justify-between align-middle">
          <BreadHeader
            title={t("My History")}
            description={t("Search, Edit and View Scores For Land Profiles")}
          />
          <Select
            onChange={(item) => setActiveModule(item.value)}
            options={optionsSelect}
            className="dark:text-black pt-4 h-fit"
            placeholder={t("Select Type")}
          />
        </div>

        {/* <Select /> */}
        {activeModule === "crops" ? (
          <div>
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-7 mb-8">
              <div className="bg-card text-card-foreground bg-[#F9F9F9] border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7] col-span-2">
                <div className="flex flex-col space-y-1.5 p-6">
                  <h3 className="font-bold text-2xl leading-none tracking-tight">
                    {t("Crops Searched")}
                  </h3>
                  <Doughnut data={dataPie} />
                </div>
              </div>
              <div className="bg-[#F9F9F9] border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7] bg-card col-span-3 p-7">
                <div>
                  <h3 className="font-bold text-2xl leading-none tracking-tight">
                    {t("Monthly Activity")}
                  </h3>
                  {/* <Line data={data} /> */}
                  <Line options={options} data={data} />
                  {/* <StackedBarChart data={scoreList?.scores || []} /> */}
                </div>
              </div>
              <div className="bg-card text-card-foreground bg-[#F9F9F9] border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7] col-span-2">
                <div className="flex flex-col space-y-1.5 p-6">
                  <h3 className="font-bold text-2xl leading-none tracking-tight mb-5">
                    {t("Average Risk Score")}
                  </h3>
                  <Gauge level={getAverageRisk()} />
                  <div>
                    <div className="lg:block hidden mt-16">
                      <div className="flex justify-between items-center gap-x-8 px-8">
                        <div aria-label="one">
                          <div className="flex gap-4 items-center">
                            <svg
                              width={50}
                              height={20}
                              viewBox="0 0 50 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width={50}
                                height={20}
                                rx={3}
                                fill="#2ca049"
                              />
                            </svg>
                            <p className="text-sm font-medium leading-3 text-gray-800">
                              {t("Low")}
                            </p>
                          </div>

                          <div className="flex items-center gap-4 mt-4">
                            <svg
                              width={50}
                              height={20}
                              viewBox="0 0 50 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width={50}
                                height={20}
                                rx={3}
                                fill="#f8c33a"
                              />
                            </svg>
                            <p className="text-sm font-medium leading-3 text-gray-800">
                              {t("Medium")}
                            </p>
                          </div>
                        </div>
                        <div aria-label="two">
                          <div className="flex items-center gap-4">
                            <svg
                              width={50}
                              height={20}
                              viewBox="0 0 50 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width={50}
                                height={20}
                                rx={3}
                                fill="#f6784e"
                              />
                            </svg>
                            <p className="text-sm font-medium leading-3 text-gray-800">
                              {t("High")}
                            </p>
                          </div>

                          <div className="flex items-center gap-4 mt-4">
                            <svg
                              width={50}
                              height={20}
                              viewBox="0 0 50 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width={50}
                                height={20}
                                rx={3}
                                fill="#BF181D"
                              />
                            </svg>
                            <p className="text-xs max-w-[38px] font-medium leading-3 text-gray-800">
                              {t("Very High")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <EngagementTable scoreList={scoreList} refetch={refetch} />
          </div>
        ) : (
          <>
            {" "}
            <div>
              <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-7 mb-8">
                <div className="bg-card text-card-foreground bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7] col-span-2">
                  <div className="flex flex-col space-y-1.5 p-6">
                    <h3 className="font-semibold text-sm leading-none tracking-tight">
                      {t("Livestock")}
                    </h3>
                    <Doughnut data={dataPieLivestock} />
                  </div>
                </div>
                <div className="bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7] bg-card col-span-3">
                  <div>
                    <h3 className="font-semibold text-sm leading-none tracking-tight p-5">
                      {t("Monthly Activity")}
                    </h3>
                    {/* <Line data={data} /> */}
                    <Line options={options} data={data} />
                    {/* <StackedBarChart data={scoreList?.scores || []} /> */}
                  </div>
                </div>
                <div className="bg-card text-card-foreground bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7] col-span-2">
                  <div className="flex flex-col space-y-1.5 p-6">
                    <h3 className="font-semibold text-sm leading-none tracking-tight">
                      {t("Average Risk Score")}
                    </h3>
                    <Gauge level={getAverageRiskLivestock()} />
                    <div>
                      <div className="lg:block hidden mt-16">
                        <div className="flex justify-between items-center gap-x-4 px-8">
                          <div aria-label="one">
                            <div className="flex gap-2 items-center">
                              <svg
                                width={20}
                                height={8}
                                viewBox="0 0 20 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  width={20}
                                  height={8}
                                  rx={4}
                                  fill="#2ca049"
                                />
                              </svg>
                              <p className="text-xs font-medium leading-3 text-gray-800">
                                {t("Low")}
                              </p>
                            </div>

                            <div className="flex items-center gap-2 mt-8">
                              <svg
                                width={20}
                                height={8}
                                viewBox="0 0 20 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  width={20}
                                  height={8}
                                  rx={4}
                                  fill="#f8c33a"
                                />
                              </svg>
                              <p className="text-xs font-medium leading-3 text-gray-800">
                                {t("Medium")}
                              </p>
                            </div>
                          </div>
                          <div aria-label="two">
                            <div className="flex items-center gap-2">
                              <svg
                                width={20}
                                height={8}
                                viewBox="0 0 20 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  width={20}
                                  height={8}
                                  rx={4}
                                  fill="#f6784e"
                                />
                              </svg>
                              <p className="text-xs font-medium leading-3 text-gray-800">
                                {t("High")}
                              </p>
                            </div>

                            <div className="flex items-center gap-2 mt-8">
                              <svg
                                width={20}
                                height={8}
                                viewBox="0 0 20 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  width={20}
                                  height={8}
                                  rx={4}
                                  fill="#BF181D"
                                />
                              </svg>
                              <p className="text-xs  leading-3 text-gray-800">
                                {t("Very High")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <EngagementTable scoreList={scoreList} refetch={refetch} /> */}
              <EngagmentLivestock
                scoreList={liveStockScores}
                refetch={refetch}
              />
            </div>
          </>
        )}
      </div>
    </MainDashboard>
  );
}
