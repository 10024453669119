import { useEffect, useState } from "react";

const ProgressBarHome = ({ category, score, idle }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [displayedScore, setDisplayedScore] = useState(0);

  useEffect(() => {
    setDisplayedScore(score);
  }, [score]);
  const pickColor = (score) => {
    if (score >= 0 && score <= 30) {
      return "#6ebd5f";
    } else if (score > 30 && score <= 40) {
      return "#b0bf58";
    } else if (score > 40 && score <= 50) {
      return "#f6ca5a";
    } else if (score > 50 && score <= 60) {
      return "#fac25d";
    } else if (score > 60 && score <= 70) {
      return "#fa9359";
    } else if (score > 70 && score <= 100) {
      return "#fc5874";
    } else {
      return "#e5e7eb";
    }
  };
  console.log("dis", displayedScore);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="w-full relative h-6  rounded-full dark:bg-gray-700"
      style={{
        background: !idle ? "#f3f4f6" : "",
      }}
    >
      <div
        className={`h-6 dark:bg-blue-500`}
        style={{
          backgroundColor: `${pickColor(score)}`,
          width: !idle
            ? "0%"
            : `${
                displayedScore === 0
                  ? "5"
                  : `${displayedScore === -1 ? "100" : `${displayedScore}`}`
              }%`,
          borderRadius: idle ? "6px" : "100px",
          transition: "width 0.5s ease-in-out",
        }}
      ></div>
      {isHovered && idle ? (
        <div className="absolute flex flex-col gap-1 top-[-50px] left-16 bg-gray-800 text-white text-xs rounded px-2 py-1">
          <p>{category}</p>
          <div className="border-b"></div>
          <p>
            {" "}
            <span
              style={{ backgroundColor: pickColor(score) }}
              className="inline-block w-2 h-2  rounded-full"
            ></span>{" "}
            Score:{Math.floor(score)}
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProgressBarHome;
