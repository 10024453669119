import { CreditCard, Zap } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const PricingCard = ({ product, isPopular, email, fullname, userId }: any) => {
  const discountedPrice = (
    product.price *
    (1 - product.discount_percentage / 100)
  ).toFixed(2);
  const navigate = useNavigate();
  const { t } = useTranslation("home");
  return (
    <div
      className={`bg-white rounded-xl shadow-xl p-8 flex flex-col h-full transform transition-all duration-300 hover:scale-105 ${
        isPopular ? "border-4 border-indigo-500 relative" : ""
      }`}
    >
      {isPopular && (
        <span className="bg-indigo-500 text-white text-sm font-semibold px-3 py-1 rounded-full absolute top-0 right-0 transform translate-x-2 -translate-y-2">
          {t("Most Popular")}
        </span>
      )}
      <div className="flex-grow">
        <CreditCard className="w-16 h-16 text-indigo-600 mb-6" />
        <h3 className="text-2xl font-bold mb-2">{product.description}</h3>
        <p className="text-gray-600 mb-6">
          {t("Unlock powerful search capabilities")}
        </p>
        <div className="mb-4">
          <span className="text-5xl font-extrabold text-indigo-600">
            ${discountedPrice}
          </span>
          <span className="text-xl text-gray-500 line-through ml-3">
            ${product.price}
          </span>
        </div>
        <p className="text-green-500 font-semibold text-lg mb-6">
          {t("Save")} {product.discount_percentage}%
        </p>
        <ul className="mb-8 space-y-3">
          {
            //@ts-ignore
            // product.features.map((feature, index) => (
            //   <li key={index} className="flex items-center">
            //     <Check className="w-5 h-5 text-green-500 mr-2" />
            //     <span>{feature}</span>
            //   </li>
            // ))
          }
        </ul>
      </div>
      <button
        onClick={() => {
          navigate(
            `/plancheckout?email=${encodeURIComponent(
              email
            )}&fullname=${encodeURIComponent(
              fullname
            )}&userId=${encodeURIComponent(
              userId
            )}&priceId=${encodeURIComponent(product.price_id)}`
          );
        }}
        className="bg-indigo-600 text-white py-3 px-6 rounded-lg font-semibold text-lg hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg flex items-center justify-center"
      >
        {t("Get Started")}
        <Zap className="ml-2 w-5 h-5" />
      </button>
    </div>
  );
};
export default PricingCard;
