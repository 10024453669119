// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation JSON files for different pages
import homeEN from './locales/en/home.json';
import scoreEN from './locales/en/scoreHistory.json';
import helpEN from './locales/en/help.json';

import homeFR from './locales/fr/home.json';
import scoreFR from './locales/fr/scoreHistory.json';
import helpFR from './locales/fr/help.json';


import homeES from './locales/es/home.json';
import scoreES from './locales/es/scoreHistory.json';
import helpES from './locales/es/help.json';


import homePT from './locales/pt/home.json';
import scorePT from './locales/pt/scoreHistory.json';
import helpPT from './locales/pt/help.json';


import homeDE from './locales/de/home.json';
import scoreDE from './locales/de/scoreHistory.json';
import helpDE from './locales/de/help.json';

import homeHI from './locales/hi/home.json';
import scoreHI from './locales/hi/scoreHistory.json';
import helpHI from './locales/hi/help.json';

import homeZH from './locales/zh/home.json';
import scoreZH from './locales/zh/scoreHistory.json';
import helpZH from './locales/zh/help.json';

import homeID from './locales/id/home.json';
import scoreID from './locales/id/scoreHistory.json';
import helpID from './locales/id/help.json';

import homeJA from './locales/ja/home.json';
import scoreJA from './locales/ja/scoreHistory.json';
import helpJA from './locales/ja/help.json';


import homeTL from './locales/tl/home.json';
import scoreTL from './locales/tl/scoreHistory.json';
import helpTL from './locales/tl/help.json';

import homeRU from './locales/ru/home.json';
import scoreRU from './locales/ru/scoreHistory.json';
import helpRU from './locales/ru/help.json';

const resources = {
  en: {
    home: homeEN,
    score: scoreEN,
  
    help: helpEN,
  },
  fr: {
    home: homeFR,
    score: scoreFR,
  
    help: helpFR,
  },
  es: {
    home: homeES,
    score: scoreES,
    
    help: helpES,
  },
  pt: {
    home: homePT,
    score: scorePT,
   
    help: helpPT,
  },
  de: {
    home: homeDE,
    score: scoreDE,
  
    help: helpDE,
  },
  hi: {
    home: homeHI,
    score: scoreHI,
  
    help: helpHI,
  },
  zh: {
    home: homeZH,
    score: scoreZH,

    help: helpZH,
  },
  id: {
    home: homeID,
    score: scoreID,
   
    help: helpID,
  },
  ja: {
    home: homeJA,
    score: scoreJA,
   
    help: helpJA,
  },
  tl: {
    home: homeTL,
    score: scoreTL,
    
    help: helpTL,
  },
  ru: {
    home: homeRU,
    score: scoreRU,
   
    help: helpRU,
  },
};

i18n
  .use(initReactI18next) // Connects i18n with react-i18next
  .init({
    resources,
    fallbackLng: 'en', // Fallback to English if the current language is missing
    debug: true,

    interpolation: {
      escapeValue: false // React already escapes values
    }
  });

export default i18n;