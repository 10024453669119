// src/context/LanguageContext.js
//@ts-nocheck
import React, { createContext, useContext, useState } from "react";

const LanguageContext = createContext({});

export const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("es");
  const [zoom, setZoom] = useState(18);

  return (
    <LanguageContext.Provider
      value={{ selectedLanguage, setSelectedLanguage, zoom, setZoom }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
