import { useTranslation } from "react-i18next";
import MainDashboard from "../components/dashboards/main_dashboard";

export default function CheckoutSuccess() {
  const { t } = useTranslation("home");
  return (
    <MainDashboard>
      <div className="min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
          <div className="text-center">
            <div className="mx-auto h-24 w-24 rounded-full bg-green-100 flex items-center justify-center">
              <svg
                className="h-16 w-16 text-green-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              {t("Your Checkout is Successful!")}
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              {t(
                "Thank you for your purchase. We've sent a confirmation email along with a payment receipt."
              )}
            </p>
          </div>
          <div className="mt-8 space-y-6">
            <div className="bg-green-50 border border-green-200 rounded-md p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-green-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-green-800">
                    {t("Order Confirmed")}
                  </h3>
                  <div className="mt-2 text-sm text-green-700">
                    <p>{t("Your order has been processed .")}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="flex items-center justify-center">
              <a
                href="#"
                className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
              >
                View Order Details
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </MainDashboard>
  );
}
