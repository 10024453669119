import { Link } from "react-router-dom";
import { useUser } from "../context/UserProvider";
import Navbar from "../components/navbar";
import { useTranslation } from "react-i18next";

export default function LandingDash() {
  const { t } = useTranslation("home");
  const { setSearchType } = useUser();
  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <Navbar isLanding={false} />
        <div className=" mx-auto text-center">
          <div className="md:flex  sm:flex-row sm:justify-center ">
            <div
              onClick={() => {
                setSearchType("livestock");
              }}
              className="md:w-7/12  relative left-0 hover-text-property md:absolute "
            >
              <Link to={"/livestock"}>
                <img
                  className="left-img"
                  src="/img/oblique-img-right.jpg"
                  alt=""
                />
                <h4 className="bottom-[15%] left-[35%] md:top-[5%] md:left-[30%] md:bottom-auto text-xl md:text-5xl absolute bg-black bg-opacity-10 rounded border border-1 border-white pointer-events-auto p-4  text-white">
                  {t("Livestock")}
                </h4>
              </Link>

              {/* <div className="p-5">
                <Link
                  to="/dashboard"
                  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Crops
                  <svg
                    className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </Link>
              </div> */}
            </div>
            <div
              onClick={() => {
                // console.log("url set to livestock");
                setSearchType("crops");
                // navigate("/livestock");
              }}
              className=" md:w-7/12  relative hover-text-property md:absolute right-0  "
            >
              <Link to="/dashboard">
                <img
                  className="right-img hover:border-1 border-color-red"
                  src="/img/oblique-img-left.jpg"
                  alt=""
                />
                <h4 className="bottom-[15%] left-[35%]  md:top-[60%] md:right-[30%] md:bottom-auto text-xl md:text-5xl absolute bg-black bg-opacity-10 border border-1 border-white rounded pointer-events-auto p-4 text-white">
                  {t("Crop")}
                </h4>
              </Link>
              {/* <div className="p-5">
                <Link
                  to=""
                  className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Livestock
                  <svg
                    className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
