import { useCallback } from "react";

import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useSearchParams } from "react-router-dom";
import MainDashboard from "../components/dashboards/main_dashboard";
import { acquireAccessToken, msalInstance } from "../authConfig";
const stripeKey = import.meta.env.VITE_STRIPE_KEY;
// const stripeRedirect = import.meta.env.VITE_STRIPE_REDIRECT;
const stripePromise = loadStripe(stripeKey);
const stripeRedirect = import.meta.env.VITE_STRIPE_REDIRECT;
const base_url = import.meta.env.VITE_API_BASE_URL_NEW;
const CheckoutPage = () => {
  const [searchParams] = useSearchParams();
  const priceId = searchParams.get("priceId");
  const fetchClientSecret = useCallback(async () => {
    // Create a Checkout Session

    const accToken = await acquireAccessToken(msalInstance);

    return fetch(`${base_url}/user/single/topup/checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accToken}`,
      },
      body: JSON.stringify({
        price_id: priceId,
        base_url: stripeRedirect,
      }),
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);

  const options = { fetchClientSecret };

  return (
    <MainDashboard>
      <div id="checkout">
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </MainDashboard>
  );
};

export default CheckoutPage;
