import { useTranslation } from "react-i18next";

export default function ProfitabilityHelp() {
  const { t } = useTranslation("help");
  return (
    <>
      {/* Card */}
      <div className="text-gray-800 body-font">
        <p className="leading-relaxed text-base pb-3">
          {t("Profitability Index")}
        </p>
      </div>
      {/* End Card */}
    </>
  );
}
