import { useTranslation } from "react-i18next";
import BreadHeader from "../../components/breadheader";
import MainDashboard from "../../components/dashboards/main_dashboard";
import CompaniesTable from "../../components/tables/companies";

export default function Companies() {
  const { t } = useTranslation("home");
  return (
    <MainDashboard>
      <div>
        <BreadHeader
          title={t("Companies")}
          description={t("List Of Companies Registered in ADAPTA")}
        />
        <CompaniesTable />
      </div>
    </MainDashboard>
  );
}
