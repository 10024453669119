import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  ArrowUpRight,
  ArrowDownRight,
  DollarSign,
  Clock,
  Zap,
} from "lucide-react";
import MainDashboard from "../components/dashboards/main_dashboard";

const usageData = [
  { name: "Jan", usage: 4000 },
  { name: "Feb", usage: 3000 },
  { name: "Mar", usage: 2000 },
  { name: "Apr", usage: 2780 },
  { name: "May", usage: 1890 },
  { name: "Jun", usage: 2390 },
];

export default function UserDashboard() {
  return (
    <MainDashboard>
      <div className="min-h-screen bg-gray-100 p-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-800 mb-8">
            User Dashboard
          </h1>

          {/* Key Metrics */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <MetricCard
              title="Total Credits"
              value="5,678"
              change={12}
              icon={<DollarSign className="w-6 h-6" />}
            />
            <MetricCard
              title="Usage This Month"
              value="1,234 hrs"
              change={-8}
              icon={<Clock className="w-6 h-6" />}
            />
            <MetricCard
              title="Efficiency Score"
              value="92%"
              change={3}
              icon={<Zap className="w-6 h-6" />}
            />
          </div>

          {/* Usage Summary */}
          <div className="bg-white rounded-lg shadow p-6 mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Usage Summary
            </h2>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={usageData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="usage" fill="#4F46E5" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Credits Overview */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Credits Overview
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="text-lg font-medium text-gray-700 mb-2">
                  Available Credits
                </h3>
                <p className="text-3xl font-bold text-indigo-600">3,500</p>
              </div>
              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="text-lg font-medium text-gray-700 mb-2">
                  Used Credits
                </h3>
                <p className="text-3xl font-bold text-indigo-600">2,178</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainDashboard>
  );
}

function MetricCard({ title, value, change, icon }) {
  const isPositive = change > 0;
  return (
    <div className="bg-white rounded-lg shadow p-6 transition duration-300 ease-in-out transform hover:scale-105">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-medium text-gray-700">{title}</h3>
        <div className="bg-indigo-100 p-2 rounded-full">{icon}</div>
      </div>
      <p className="text-3xl font-bold text-gray-900 mb-2">{value}</p>
      <p
        className={`flex items-center text-sm ${
          isPositive ? "text-green-600" : "text-red-600"
        }`}
      >
        {isPositive ? (
          <ArrowUpRight className="w-4 h-4 mr-1" />
        ) : (
          <ArrowDownRight className="w-4 h-4 mr-1" />
        )}
        {Math.abs(change)}% from last month
      </p>
    </div>
  );
}
