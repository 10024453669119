// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";

import ReactApexChart from "react-apexcharts";
import { useCustomTheme } from "../context/ThemeProvider";
import { useState } from "react";
import ProgressBarHome from "./ProgressBarHome";

type GradientBarChart = {
  categories: string[];
  scores: any;
  composite?: boolean;
};

const GradientBarChart = ({
  categories,
  scores,
  idle,
}: // composite,
GradientBarChart) => {
  // console.log("GradientBarChart:indexScores: ", scores, categories);
  const { customTheme } = useCustomTheme();
  console.log(customTheme, "theme");
  const pickColor = (score) => {
    if (score >= 0 && score <= 30) {
      return "#6ebd5f";
    } else if (score > 30 && score <= 40) {
      return "#b0bf58";
    } else if (score > 40 && score <= 50) {
      return "#f6ca5a";
    } else if (score > 50 && score <= 60) {
      return "#fac25d";
    } else if (score > 60 && score <= 70) {
      return "#fa9359";
    } else if (score > 70 && score <= 100) {
      return "#fc5874";
    } else {
      return "#e5e7eb";
    }
  };

  // const options = {
  //   series: [
  //     {
  //       name: "Risk",
  //       data: scores,
  //     },
  //   ],
  //   plotOptions: {
  //     bar: {
  //       barHeight: "20%",
  //       distributed: true,
  //       horizontal: true,
  //       borderRadius: 10,
  //     },
  //   },

  //   colors: scores.map((sc) => pickColor(sc)),
  //   dataLabels: {
  //     enabled: false,
  //     textAnchor: "start",

  //     formatter: function (val, opt) {
  //       return val;
  //     },
  //     dropShadow: {
  //       enabled: false,
  //     },
  //   },
  //   stroke: {
  //     lineCap: "round",
  //   },
  //   xaxis: {
  //     categories: categories,
  //     labels: {
  //       show: true,
  //       formatter: (val) => {
  //         if (val == 10) {
  //           return "Low";
  //         } else if (val == 100) {
  //           return "High";
  //         } else {
  //           return;
  //         }
  //       },
  //     },
  //   },
  //   yaxis: {
  //     min: 10,
  //     max: 100,
  //     labels: {
  //       show: true,

  //       align: "left",
  //       offsetX: -5,
  //       style: {
  //         colors:
  //           composite === true && customTheme === "dark"
  //             ? ["#fff"]
  //             : ["#000000"],
  //         fontSize: "0.7rem",
  //       },
  //     },
  //   },
  //   grid: {
  //     show: false, // Set to false to hide grid lines
  //   },
  //   legend: {
  //     show: false,
  //     showForSingleSeries: true,
  //   },
  //   chart: {
  //     toolbar: {
  //       mode: "dark",
  //       show: false,
  //     },
  //     animations: {
  //       enabled: true,
  //       speed: 1500,
  //       animateGradually: {
  //         enabled: true,
  //         delay: 1500,
  //       },
  //       dynamicAnimation: {
  //         enabled: true,
  //         speed: 1500,
  //       },
  //     },
  //   },
  //   theme: {
  //     palette: "palette10",
  //     monochrome: {
  //       enabled: false,
  //       color: "#255aee",
  //       shadeTo: "light",
  //       shadeIntensity: 0.65,
  //     },
  //   },
  // };

  return (
    <div id="chart" style={{ marginTop: "-1.5rem", color: "black" }}>
      <div className="flex flex-col gap-2 w-[100%] ">
        <div>
          <p className="text-xs p-1 font-medium text-[#404040] dark:text-[#FFFFF7]">
            {categories[0]}
          </p>
          <ProgressBarHome
            idle={idle}
            category={categories[0]}
            score={scores[0].data[0]}
          />
        </div>
        <div>
          <p className="text-xs p-1 font-medium text-[#404040] dark:text-[#FFFFF7]">
            {categories[1]}
          </p>
          {/* <div class="w-full h-6 bg-gray-100 rounded-full dark:bg-gray-700">
            <div
              class={`h-6   rounded-full dark:bg-blue-500`}
              style={{
                // width: `${scores[0].data[1]}%`,
                backgroundColor: `${pickColor(scores[0].data[1])}`,
                width: !idle ? "0%" : `${scores[0].data[1]}%`,
              }}
            ></div>
          </div> */}
          <ProgressBarHome
            idle={idle}
            category={categories[1]}
            score={scores[0].data[1]}
          />
        </div>
        <div>
          <p className="text-xs p-1 font-medium text-[#404040] dark:text-[#FFFFF7]">
            {categories[2]}
          </p>
          {/* <div class="w-full h-6 bg-gray-100 rounded-full dark:bg-gray-700">
            <div
              class={`h-6  rounded-full dark:bg-blue-500`}
              style={{
                // width: `${scores[0].data[2]}%`,
                backgroundColor: `${pickColor(scores[0].data[2])}`,
                width: !idle ? "0%" : `${scores[0].data[2]}%`,
              }}
            ></div>
          
          </div> */}
          <ProgressBarHome
            idle={idle}
            category={categories[2]}
            score={scores[0].data[2]}
          />
        </div>
      </div>
    </div>
  );
};

export default GradientBarChart;
