import IdleMiniChart from "./IdleMiniChart";

function IdleGauge() {
  return (
    <>
      <div className="pt-2 w-100">
        <div className="mx-auto">
          <div className="group flex flex-col">
            <div className="flex justify-center items-center">
              <IdleMiniChart
                // @ts-ignore
                indexScore={0}
                width={200}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IdleGauge;
