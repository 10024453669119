import { useTranslation } from "react-i18next";
import donut from "/donut.png";

import whitedot from "/whitedot.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

interface MiniChartProps {
  indexScore: number;
  width: number;
}
const IdleMiniChart = ({ indexScore, width }: MiniChartProps) => {
  const { t } = useTranslation("home");
  // const indicatorPosition = indexScore / 100;
  // const rotationValue = `rotate(calc(${indicatorPosition} * 140deg))`;
  // const translationValue = `translateY(41%)`;
  const percentageValue = width + "px";
  return (
    <div
      id="chart"
      className="flex justify-center items-center float-left   relative"
    >
      <img
        style={{
          width: `${width}px`,
        }}
        src={donut}
        className={`${width}px`}
        // className="w-full"
      />
      <div className="absolute font-bold right-[64px] top-3 text-xs">
        {" "}
        {t("High")}
      </div>
      <div
        className=""
        style={{
          position: "absolute",
          zIndex: 30,
          margin: "auto",
          width: `${percentageValue}`,
          aspectRatio: "1/1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // transform: `${rotationValue} ${translationValue}`,
          transform: "rotate(283deg) translateY(40%)",
        }}
      >
        <img src={whitedot} style={{ width: "15%", margin: "auto" }} />
      </div>
      <div className="absolute left-[165px] font-bold text-xs bottom-[100px]">
        {t("Low")}
      </div>
      <div className="absolute z-60 flex flex-col justify-center items-center h-full mx-auto">
        <p id="ind_t_2" className="text-2xl font-semibold dark:text-[#454545]">
          {indexScore ? indexScore : "--"}
        </p>
      </div>
    </div>
  );
};

export default IdleMiniChart;
