// src/context/LanguageContext.js
//@ts-nocheck
import React, { createContext, useContext, useState } from "react";

const PaymentContext = createContext({});

export const PaymentProvider = ({ children }) => {
  const [credits, setCredits] = useState("Loading..");

  return (
    <PaymentContext.Provider value={{ credits, setCredits }}>
      {children}
    </PaymentContext.Provider>
  );
};

export const usePayment = () => useContext(PaymentContext);
