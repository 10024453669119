import { useTranslation } from "react-i18next";

export default function SoilHelp() {
  const { t } = useTranslation("help");
  return (
    <>
      {/* Card */}
      <div className="text-gray-800 body-font">
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("Topsoil fertility")}
        </h1>

        <p className="leading-relaxed text-base pb-3">
          {t(
            "Soil Organic Carbon (SOC) is one of the measures of Topsoil fertility. It is the amount of carbon stored in the soil in the form of organic matter. It is primarily derived from the decomposition of plants, animals, microbes, leaves, and wood on the ground. In a garden, when leaves fall off the trees and plants die, they fall onto the ground. With time, tiny creatures, often fungi, break down these bits and mix them into the soil. This mixture of broken-down plants, animals, and any other material is organic matter."
          )}
        </p>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "Organic matter improves soil structure by acting as a bonding agent that holds soil particles together in aggregates. Without organic matter, aggregates are less stable and can be easily broken apart. Good soil structure promotes water movement and root penetration while reducing soil crusting, clod formation, and erosion."
          )}
        </p>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "Organic matter provides plant nutrients, mainly nitrogen and sulfur, and smaller amounts of phosphorus. About 20 pounds (9 kgs) of nitrogen are released by decomposition of every 1 percent of organic matter in the soil. Organic matter is a primary reservoir for available forms of micronutrients (mainly zinc and boron)."
          )}
        </p>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "Soil organic matter also improves the cation exchange capacity of the soil, its ability to hold positively charged molecules, or ions, of mineral nutrients."
          )}
        </p>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "SOC levels below a certain threshold impair key soil functions, such as water retention, nutrient cycling, and microbial activity."
          )}
        </p>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "For many soils, a minimum SOC content of 2% (20 g/kg) is often considered necessary for maintaining good soil structure and functionality."
          )}
        </p>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "A SOC level of 1.5–2% is typically considered the lower limit for sustaining crop productivity in arable lands."
          )}
        </p>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "SOC content below 1% (10 g/kg) often indicates severe soil degradation, requiring urgent interventions like cover cropping, organic amendments, or reforestation."
          )}
        </p>
        <p className="leading-relaxed text-base pb-1">
          {t("To improve soil organic matter content:")}
        </p>
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            {t(
              "Practice Mulching: Apply mulches like crop residue and wood chips on the soil surface."
            )}
          </li>

          <li className="mb-2">
            {t(
              "Cover cropping: Plant crops that can add organic matter to the soil."
            )}
          </li>

          <li className="mb-2">
            {t(
              "Reduce tillage or practice no-tillage farming: Minimal soil tillage prevents the breakdown of organic matter and releases carbon dioxide, thus retaining organic carbon in the soil."
            )}
          </li>

          <li className="mb-2">
            {t(
              "Green Manure: Grow green manure crops that are later incorporated into the soil."
            )}
          </li>
        </ul>

        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("Soil PH")}
        </h1>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "This is the measure of acidity or alkalinity of the soil determined by the presence of hydrogen ions or hydroxide ions. The soil pH scale runs from 0 to 14. A pH of 7 is considered neutral, meaning the soil is neither acidic nor alkaline. A pH below 7 is considered acidic (like lemon juice) and a pH above 7 is considered alkaline (like baking soda)."
          )}
        </p>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "The pH of your soil affects how well plants can absorb different nutrients from the soil. Some plants prefer it a bit acidic, like blueberries, tea, etc. while others, like asparagus, dates, and eggplants prefer it more alkaline. Most plants like it somewhere in the middle, between 6 to 7.0.In acid soils (pH is low) calcium and magnesium become more available to plants, whereas the micronutrients iron, aluminum, and manganese become soluble and can reach levels toxic to plants. These micronutrients also can react with phosphorus to form compounds that are insoluble and not available to plants. In alkaline soils (pH is high), several soil micronutrients, including zinc, copper, and cobalt, become less available to plants. Also at high pH, phosphorus precipitates (becomes insoluble) with the higher levels of calcium in the soil and therefore becomes less available to plants."
          )}
        </p>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "Soil pH affects the population and activity of microorganisms. The activity of nitrogen-fixing bacteria associated with legumes is impaired in acid soils, resulting in less nitrogen fixation."
          )}
        </p>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "It is important to manage soil pH because it helps plants get all the nutrients required to grow thus becoming healthy. If soil pH isn’t right, a farmer may need to add limestone (agricultural lime) to make acidic soil less acidic and gypsum (calcium sulfate), to make alkaline soils less alkaline."
          )}
        </p>
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("Nutrient Holding Capacity")}
        </h1>

        <p className="leading-relaxed text-base pb-3">
          {t(
            "Nutrient Holding Capacity (NHC), commonly referred to as Cation Exchange Capacity (CEC) is the ability of a soil to retain, exchange, and supply essential nutrients (positively charged ions, known as cations), to plant roots."
          )}
        </p>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "These cations include nutrients like calcium (Ca²⁺), magnesium (Mg²⁺), potassium (K⁺), and others, which are vital for plant growth."
          )}
        </p>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "In soil tests, CEC is reported in milliequivalents (meq) per 100 grams of soil. The exchangeable cations in the soil are in equilibrium with those in the soil solution (water in the soil). As plants remove nutrients (cations) from the soil solution, they are replenished from the adsorbed cations, which are then available for plant uptake."
          )}
        </p>
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            {t("The higher the CEC, the more cations a soil can retain.")}
          </li>

          <li className="mb-2">
            {t(
              "Soils differ in CEC depending on clay and organic matter content. CEC of clay varies from 4 to 100 meq per 100 g. Humus has an average CEC of 200 meq per 100 g."
            )}
          </li>

          <li className="mb-2">
            {t(
              "Soil texture affects CEC. The more clay, the higher the CEC (see below)."
            )}
          </li>

          <li className="mb-2">
            {t(
              "Soils with low CEC (1 to 10) have high sand content and low water-holding capacity"
            )}
          </li>

          <li className="mb-2">
            {t(
              "Soils with high CEC (15 to 40) have high clay or humus content, high water-holding capacity, and a greater capacity to hold nutrients."
            )}
          </li>
        </ul>
        <div className="m-6">
          <table className="border w-100 md:min-w-[700px] table-auto ">
            <tbody className="border p-2 ">
              <tr>
                <td>
                  <table>
                    <tr>
                      <td colSpan={12} className="bg-blue-300 p-2  font-bold">
                        {t("Soil")}
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-2 w-[5%]">1</td>
                      <td className="border p-2 w-[35%]">
                        {t("Sand")}
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-2">2</td>
                      <td className="border p-2">{t("Sandy loam")}</td>
                    </tr>
                    <tr>
                      <td className="border p-2">3</td>
                      <td className="border p-2">{t("Loams")}</td>
                    </tr>
                    <tr>
                      <td className="border p-2">4</td>
                      <td className="border p-2">
                        {t("Silk and silt clay loams")}
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-2">4</td>
                      <td className="border p-2">
                        {t("Clay and clay loams")}
                      </td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table>
                    <tr>
                      <td
                        colSpan={12}
                        className="bg-blue-300 p-2  font-semibold"
                      >
                        {t("CEC(meq/100g)")}
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-2 w-[35%]">
                        {t("2 to 5")}
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-2">{t("5 to 12")}</td>
                    </tr>
                    <tr>
                      <td className="border p-2">{t("10 to 18")}</td>
                    </tr>
                    <tr>
                      <td className="border p-2">
                        {t("15 to 30")}
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-2">
                        {t("25 to 40")}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="leading-relaxed text-base pb-3 font-semibold">
          {t("To improve the nutrient-holding capacity of the soil:")}
        </p>
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            {t(
              "Incorporate organic matter such as compost, well-rotted manure, or cover crops into the soil. Organic matter has a high CEC and can significantly improve the soil's nutrient-holding capacity."
            )}
          </li>

          <li className="mb-2">
            {t(
              "Apply mulch to the soil surface to gradually add organic matter over time. Mulch helps improve soil structure, moisture retention, and microbial activity, contributing to higher CEC."
            )}
          </li>

          <li className="mb-2">
            {t(
              "Implement erosion control measures to prevent the loss of topsoil rich in organic matter. This helps maintain or increase CEC over time."
            )}
          </li>

          <li className="mb-2">
            {t(
              "Rotate crops and incorporate cover crops that add organic matter to the soil. Legumes, such as soybean, clover, and peas, can fix atmospheric nitrogen, enhancing soil fertility and CEC."
            )}
          </li>

          <li className="mb-2">
            {t(
              "Adjust the soil's pH using lime (for acidic soils) or gypsum (for sodic/alkaline soils). Proper pH levels can improve CEC by making nutrients more available for plants."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Avoid over-fertilization, leading to nutrient imbalances and negatively affecting CEC. Follow recommended nutrient application rates based on soil testing."
            )}
          </li>
          <li className="mb-2">
            {t(
              "The addition of clay minerals to sandy soils can increase CEC. Combining clay with organic matter can further enhance CEC."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Regular soil testing helps monitor CEC levels and nutrient status. Based on test results, adjust soil management practices."
            )}
          </li>
        </ul>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "Remember that improving CEC is a gradual process; how much you can enhance it depends on your soil type, climate, and local conditions. Soil management should be tailored to your farm's specific needs. Regular soil testing and a long-term approach to soil improvement are key to sustainable and productive agriculture."
          )}
        </p>
      </div>

      {/* End Card */}
    </>
  );
}
