import { useTranslation } from "react-i18next";
import BreadHeader from "../../components/breadheader";
import MainDashboard from "../../components/dashboards/main_dashboard";
import LivestockList from "../../components/list/livestockList";

export default function LivestockResources() {
  const { t } = useTranslation("help");
  return (
    <MainDashboard>
      <div>
        <BreadHeader
          title={t("Resources")}
          description={t(
            "List of Documents that provides more details about the livestock."
          )}
        />
        <LivestockList />
      </div>
    </MainDashboard>
  );
}
