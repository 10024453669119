import { useTranslation } from "react-i18next";
import BreadHeader from "../../components/breadheader";
import MainDashboard from "../../components/dashboards/main_dashboard";
import TeamTable from "../../components/tables/team";

export default function TeamMembers() {
  const { t } = useTranslation("home");
  return (
    <MainDashboard>
      <div>
        <BreadHeader
          title={t("Team Members")}
          description={t("Invite, Edit and Search Your Team Members")}
        />
        <TeamTable />
      </div>
    </MainDashboard>
  );
}
