import { Resolution } from "react-to-pdf";

export function replaceUnderscores(str) {
  if (str)
    return str
      ?.replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());

  return str;
}

export const pdfOptions = {
  // default is `save`
  method: "save",
  // default is Resolution.MEDIUM = 3, which should be enough, higher values
  // increases the image quality but also the size of the PDF, so be careful
  // using values higher than 10 when having multiple pages generated, it
  // might cause the page to crash or hang.
  resolution: Resolution.NORMAL,
  page: {
    // margin is in MM, default is Margin.NONE = 0
    margin: 10,
    // default is 'A4'
    format: "A4",
    // default is 'portrait'
    orientation: "Portrait",
  },
  canvas: {
    // default is 'image/jpeg' for better size performance
    mimeType: "image/jpg",
    qualityRatio: 1,
  },

  // Customize any value passed to the jsPDF instance and html2canvas
  // function. You probably will not need this and things can break,
  // so use with caution.
  overrides: {
    // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
    pdf: {
      compress: true,
    },
    // see https://html2canvas.hertzen.com/configuration for more options
    canvas: {
      useCORS: true,
    },
  },
};

export const search = (data, searchValue, param1, param2) => {
  if (!searchValue) return data;
  return data?.filter(
    (item) =>
      item[param1]
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchValue.toLowerCase()) ||
      item[param2]
        ?.toString()
        ?.toLowerCase()
        ?.includes(searchValue.toLowerCase())
  );
};
export function sortedData(data, key) {
  return data?.sort(function (a, b) {
    // here a , b is whole object, you can access its property
    //convert both to lowercase
    let x = a[key]?.toLowerCase();
    let y = b[key]?.toLowerCase();

    //compare the word which is comes first
    if (x > y) {
      return 1;
    }
    if (x < y) {
      return -1;
    }
    return 0;
  });
}

export function changeKeys(data, keyMapping) {
  return data.map((item) => {
    let newItem = {};
    for (let oldKey in item) {
      if (keyMapping[oldKey]) {
        newItem[keyMapping[oldKey]] = item[oldKey];
      }
    }
    return newItem;
  });
}

// export function roundToFiveDecimals(value) {
//   return parseFloat(value.toFixed(5));
// }
