//@ts-ignore
import markerImage from "/marker.png";

const ProgressBar = ({ progress, piller, width }) => {
  return (
    <div
      className=" flex    items-center  h-16 border-l-2 p-4 "
      style={{ width }}
    >
      <div className="w-full relative  h-4 bg-gray-100 rounded-full dark:bg-gray-700">
        <div
          className="h-4  gradient  rounded-full dark:bg-blue-500"
          style={{ width: `${100}%` }}
        ></div>
        <span className="text-sm">{piller}</span>
        <div
          className="absolute  flex flex-col items-center"
          style={{
            left: `${progress === 0 ? progress + 5 : progress - 10}%`,
            top: "-30px",
            transform: "translateX(-50)%",
          }}
        >
          {/* Progress Value */}

          <span className="text-sm font-bold text-blue-600 mb-1">
            {Math.floor(progress)}
          </span>

          {/* Marker Image */}
          <img src={markerImage} alt="Marker" className=" object-contain" />
        </div>
      </div>

      {/* Marker Image with Progress Value */}
    </div>
  );
};

export default ProgressBar;
