//@ts-nocheck
import React, { useRef, useCallback, useState } from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

type SearchBox = google.maps.places.SearchBox;

interface Location {
  lat: number;
  lng: number;
  address?: string;
}

interface AutocompleteInputProps {
  onLocationSelect: (location: Location | null, address?: string) => void;
  value?: string;
  setGeoJson: (geoJson: object) => void;
  googleMapsApiKey: string; // Add this prop to pass in your API key
}

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  onLocationSelect,
  value,
  setOpenPopup,
  inputValueAuto,
  setInputValueAuto,
  setGeoJson,
  // Destructure the API key
}) => {
  const searchBoxRef = useRef<SearchBox>();
  const { t, i18n } = useTranslation("home");
  // Handle event when the search box is loaded
  const onSearchBoxLoad = useCallback((searchBox: SearchBox) => {
    searchBoxRef.current = searchBox;
  }, []);

  // Handle event when the location is changed
  const onPlacesChanged = () => {
    setGeoJson({});
    const searchBox = searchBoxRef.current;

    if (!searchBox) return;
    // Get the place results from the search box
    const places = searchBox.getPlaces();
    if (!places || places.length === 0) return;
    console.log(places, "address");

    // Get the lat and lng of the first result
    const lat = places[0].geometry?.location?.lat();
    const lng = places[0].geometry?.location?.lng();

    //@ts-ignore

    const address = places[0].formatted_address;
    // Set the input field to show the selected address
    if (address) {
      setInputValueAuto(address);
    }

    // Set the coordinates state
    if (lat && lng) {
      onLocationSelect(
        {
          lat: lat,
          lng: lng,
          address: address,
        },
        address
      );
    }
  };

  // Handle input change to allow for lat/lng values
  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValueAuto(value);
    console.log("value", value);
    // Check if input is in lat,lng format
    const latLngRegex = /^\s*(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)\s*$/;
    const match = value.match(latLngRegex);

    if (match) {
      const lat = parseFloat(match[1]);
      const lng = parseFloat(match[3]);

      if (!isNaN(lat) && !isNaN(lng)) {
        setGeoJson({});

        try {
          // Use Geocoding API to get the address from lat/lng
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${"AIzaSyCEVC1A3YRL-S4zGuuLgHnlYjlQr90P5iA"}`
          );
          const data = await response.json();

          if (data.status === "OK" && data.results.length > 0) {
            const address = data.results[0].formatted_address;
            setInputValueAuto(address); // Update the input with the found address
            onLocationSelect({ lat, lng, address }, address);
          } else {
            onLocationSelect({ lat, lng, address: "Unknown Location" });
          }
        } catch (error) {
          console.error("Geocoding failed: ", error);
          onLocationSelect({ lat, lng, address: "Unknown Location" });
        }
      }
    }
  };

  return (
    <div>
      <StandaloneSearchBox
        onLoad={onSearchBoxLoad}
        onPlacesChanged={onPlacesChanged}
      >
        <input
          type="text"
          onClick={() => setOpenPopup(true)}
          id="input-label"
          className="h-10 py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
          placeholder={t("Enter a Location or Lat,Lng")}
          value={inputValueAuto}
          onChange={handleInputChange}
        />
      </StandaloneSearchBox>
      <p className="text-gray-500 text-[10px] p-2">{value}</p>
    </div>
  );
};

export default AutocompleteInput;
