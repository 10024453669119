import { useTranslation } from "react-i18next";

export default function WaterLivestock() {
  const { t } = useTranslation("help");
  return (
    <>
      {/* Card */}
      <div className="text-gray-800 body-font">
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("Water Stress")}
        </h1>

        <p className="leading-relaxed text-base pb-3">
          {t(
            "Water stress is the imbalance between the amount of water needed (demand) and the amount of water that can be sustainably withdrawn or accessed (supply). The condition manifests when the water demand exceeds the available supply in a particular region at a specific time."
          )}
        </p>
        <p>The primary causes of water stress are:</p>

        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            {t(
              "Altered precipitation patterns: This can be caused by climate change effects such as less frequent rainfall."
            )}
          </li>

          <li className="mb-2">
            {t(
              "Inefficient water use: Misallocation of water and over-extraction can deplete water sources."
            )}
          </li>

          <li className="mb-2">
            {t(
              "Land use changes: Deforestation, wetland drainage, and disruption of natural water cycles may reduce the amount of water available in freshwater resources."
            )}
          </li>

          <li className="mb-2">
            {t(
              "Industrial water use: Industries consume significant amounts of water, and if not regulated, may lead to water stress."
            )}
          </li>

          <p className="mb-2">{t("Water stress can be mitigated by:")}</p>

          <li className="mb-2">
            {t(
              "Efficient water use: Promoting efficient water use in agriculture using water-saving technologies, water recycling, and modern irrigation techniques will reduce water consumption and demand."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Ecosystem protection: Natural wetlands that play a key role in water storage, filtration, and replenishment should be protected."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Climate mitigation practices: Agroforestry and reafforestation are key practices that can reduce water stress by altering precipitation patterns and increasing temperatures."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Groundwater management: Groundwater resources can be managed sustainably by monitoring and controlling excessive extraction and allowing for recharge."
            )}
          </li>
        </ul>

        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("Groundwater Availability")}
        </h1>

        <p className="leading-relaxed text-base pb-3">
          {t(
            "Groundwater availability is the presence of water in the pores and spaces between soil and rocks stored beneath the earth’s surface in underground aquifers. It is a vital source of freshwater and is used for various purposes, including drinking water supply, agricultural irrigation, industrial processes, and more. Some of the effects of drought on crop production include."
          )}
        </p>
        <p>Key factors influencing Groundwater availability: </p>
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            {t(
              "Recharge rate: The rate at which water infiltrates the ground and replenishes the groundwater supply. Factors like precipitation, snowmelt, and surface water flow can influence this."
            )}
          </li>

          <li className="mb-2">
            {t(
              "Withdrawals/Extraction rates: The amount of groundwater that is pumped out for various uses. Over-extraction of groundwater can lead to groundwater depletion and a decrease in availability."
            )}
          </li>

          <li className="mb-2">
            {t(
              "Geological and hydrogeological conditions: The permeability and characteristics of the subsurface rock and soil layers play a significant role in determining how easily groundwater can be accessed."
            )}
          </li>

          <li className="mb-2">
            {t(
              "Groundwater quality: The presence of contaminants or pollutants in the groundwater can impact its availability for safe and sustainable use."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Climate and weather patterns: Seasonal variations in precipitation and temperature can affect the level of the groundwater table."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Land use and development: Urbanization, agriculture, and other human activities can impact the availability of groundwater by altering the recharge and withdrawal patterns."
            )}
          </li>
        </ul>

        <p className="leading-relaxed text-base pb-3">
          {t(
            "Assessing groundwater availability is essential for sustainable water resource management. It helps determine the capacity of an area to provide a reliable and sufficient supply of groundwater to meet the needs of various users while maintaining the long-term health of aquifers and the surrounding environment. Proper management practices, such as groundwater monitoring and water conservation efforts, are crucial to ensure the continued availability of groundwater for present and future generations."
          )}
        </p>
      </div>

      {/* End Card */}
    </>
  );
}
