import { PropsWithChildren, createContext, useContext, useState } from "react";

const DarkThemeContext = createContext({});

const DarkTheme = ({ children }: PropsWithChildren) => {
  const [customTheme, setCustomTheme] = useState(localStorage.getItem("theme"));

  return (
    <DarkThemeContext.Provider value={{ customTheme, setCustomTheme }}>
      {children}
    </DarkThemeContext.Provider>
  );
};
const useCustomTheme = () => {
  const context = useContext(DarkThemeContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export { DarkTheme, useCustomTheme };
