//@ts-ignore
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { axiosPrivateRiskScore, axiosProfitablity } from "../api/axiosPrivate";
import Swal from "sweetalert2";
import { PhoneInput } from "react-international-phone";
import GaugePdf from "./GaugePdf";
import ProgressBar from "./ProgressBar";
import { MapForReport } from "./PlacesAutocomplete";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useTranslateNested } from "../hooks/useAuth";
import BeatLoader from "react-spinners/BeatLoader";

interface FormData {
  debtor_name: string;
  debtor_contacts: string;
  reviewer_comments: string;
  location_score_id: string;
  saved: boolean;
  location_name: string;
}

const validationSchema = yup.object({
  debtor_name: yup.string().required("Debtor name is required"),
  debtor_contacts: yup.string(),
  reviewer_comments: yup.string().required("Reviewer comments are required"),
  location_score_id: yup.string(),
  location_name: yup.string(),
});

const PdfLivestock = ({
  profitData,
  loanPeriod,
  livestock,
  myLocation,
  riskId,
  suggestionLoader,
}: any) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: yupResolver(validationSchema) as any,
  });
  const [data, setData] = useState({});

  const { t } = useTranslation("home");

  const [countryCode, setCountryCode] = useState("ke");

  const UPDATE_URL = `/history/update/${riskId}`;
  const FEEDBACK_URL = "/v1/suggestions/livestock/feedback/insert";

  const translatedData = useTranslateNested(data, localStorage.getItem("lang"));

  const climate_indices = [t("Drought"), t("Temperature humidity")];

  const water_indices = [t("Water stress"), t("Ground Water Availability")];

  const profitability_indices = [t("Profitability")];

  const [feedbacks, setFeedbacks] = useState<{ [key: string]: string }>({});

  const handleInputChange = (suggestion: string, value: string) => {
    setFeedbacks((prev) => ({ ...prev, [suggestion]: value }));
  };

  const handleSubmitSuggestion = (e) => {
    e.preventDefault();
    const suggestion_feedbacks: any[] = Object.entries(feedbacks).map(
      ([suggestion, revision]) => ({
        revision,
        suggestion,
      })
    );

    console.log(JSON.stringify({ suggestion_feedbacks }, null, 2));
    const data = {
      report_id: riskId,
      suggestion_feedbacks: suggestion_feedbacks,
    };

    axiosPrivateRiskScore({
      method: "POST",
      url: FEEDBACK_URL,
      data: JSON.stringify(data),
    })
      .then((data) => {
        // setUser(data);
        console.log(JSON.stringify(data));
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: t("Feedback send successfully"),
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.error("API Error:", error);
        Swal.fire({
          icon: "error",
          title: t("Oops..."),
          text: t("Something went wrong!"),
        });
      });
    // Here you would send the data to your API
  };
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    delete data.location_score_id;
    data.saved = true;
    axiosProfitablity({
      method: "PATCH",
      url: UPDATE_URL,
      data: JSON.stringify(data),
    })
      .then((data) => {
        // setUser(data);
        console.log(JSON.stringify(data));
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: t("Comment Submitted"),
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.error("API Error:", error);
        Swal.fire({
          icon: "error",
          title: t("Oops..."),
          text: t("Something went wrong!"),
        });
      });
  };

  useEffect(() => {
    let temp1 = profitData?.total_scores?.adaptations ?? {};
    let newData = {}; // Temporary object to hold data

    Object.keys(temp1).forEach((key) => {
      if (Object.keys(newData).includes(temp1[key]?.Pillar)) {
        newData = {
          ...newData,
          [temp1[key]?.Pillar]: [...newData?.[temp1[key]?.Pillar], temp1[key]],
        };
      } else {
        newData[temp1[key]?.Pillar] = [temp1[key]];
      }
    });

    // Update the state with the new data
    setData(newData);
  }, [profitData]);
  console.log(profitData?.total_scores?.adaptations, ">>>>>>>>", data);

  const handlePhoneChange = (value, country) => {
    console.log("value", value);
    console.log("", country);
    setCountryCode(country?.country.iso2); // Save the ISO country code (e.g., 'us')
  };
  useEffect(() => {
    console.log(countryCode, "cunt");
  }, [countryCode]);
  return (
    <>
      {/* Invoice */}
      <div id="printablediv" className="max-w-[85rem]  mx-auto my-4 sm:my-10 ">
        <div className="flex justify-center items-center pb-3 border-gray-200 ">
          <div>
            <img alt="Bitpulse" className="w-24 mx-auto" src="/img/logo.png" />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Grid */}
          <div className="grid md:grid-cols-2 gap-3 p-4 pr-5 border-b border-gray-200 ">
            <div>
              <div className="grid space-y-3">
                <dl className="grid sm:flex flex-col gap-x-3 text-sm">
                  <dt className="min-w-[105px] max-w-[200px] text-gray-500 pb-2">
                    {t("Full Name")}:
                  </dt>
                  <dd className="max-w-[100%]  text-gray-800 ">
                    <input
                      type="text"
                      id="debtor_name"
                      className="min-w-[100%] rounded-sm border-gray-200 "
                      placeholder={t("Full Name")}
                      {...register("debtor_name")}
                    />
                    {errors.debtor_name && (
                      <div className="text-red-500">
                        {errors.debtor_name.message}
                      </div>
                    )}
                  </dd>
                </dl>
                <dl className="grid sm:flex flex-col gap-x-3 text-sm">
                  <dt className="min-w-[100px] max-w-[200px] text-gray-500">
                    {t("Phone Number")}:
                  </dt>
                  <dd className="font-medium text-gray-800 ">
                    <Controller
                      control={control}
                      name="debtor_contacts"
                      render={({ field: {} }) => (
                        <PhoneInput
                          className="w-full border-gray-200"
                          defaultCountry="ke"
                          inputClassName="w-full border-gray-200"
                          name="phone"
                          onChange={handlePhoneChange}
                        />
                      )}
                    />
                    {errors.debtor_contacts && (
                      <div className="text-red-500">
                        {errors.debtor_contacts.message}
                      </div>
                    )}
                  </dd>
                </dl>
              </div>
            </div>
            {/* Col */}
            <div>
              <div className="grid space-y-3">
                <dl className="grid sm:flex flex-col gap-x-3 text-sm">
                  <dt className="min-w-[150px] max-w-[200px] text-gray-500 pb-2">
                    {t("Location(Town)")}:
                  </dt>
                  <dd className="min-w-[220px] font-medium text-gray-800 ">
                    <input
                      type="text"
                      {...register("location_name")}
                      id="input-label"
                      className="min-w-[100%] rounded-sm border-gray-200    "
                      placeholder={t("Location(Town)")}
                    />
                    <address className="not-italic font-normal">
                      {`LatLng(${myLocation?.lat || "Latitude"}, ${
                        myLocation?.lng || "Longitude"
                      })`}
                    </address>
                  </dd>
                </dl>
              </div>
            </div>
            {/* Col */}
          </div>
          {/* End Grid */}
          <div className="pt-8 px-4 sm:px-6 lg:px-8">
            {/* Grid */}

            {/* End Grid */}

            <div className="">
              <h4 className="text-xl  font-semibold  text-gray-800">
                {t("Detailed Risk Report Analysis")}
              </h4>
            </div>

            {/* Table */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 border border-gray-200 p-4 rounded-lg  ">
              <div className="flex flex-col items-center h-[300px]">
                <h3 className="mb-3 font-semibold text-gray-800  ">Map</h3>
                <MapForReport lat={myLocation?.lat} lng={myLocation?.lng} />
              </div>
              <div className="flex flex-col items-center">
                <h3 className=" mb-6 font-semibold text-gray-800  ">
                  {t("Composite Risk Score")}
                </h3>

                <ProgressBar
                  //@ts-ignore
                  isBig={true}
                  progress={profitData?.total_scores?.composite_total_risk}
                  width={"100%"}
                />
                <div className="flex items-center justify-between w-[80%]  relative">
                  {/* Left Label */}
                  <div className="font-bold text-xs">L</div>

                  {/* Line with Divisions */}
                  <div className="relative flex-grow mx-2">
                    {/* Main Line */}
                    <div className="h-[1px] bg-black w-full relative">
                      {/* Division Marks */}
                      <div className="absolute h-[10px] w-[1px] bg-black top-[-4px] left-[25%]"></div>
                      <div className="absolute h-[10px] w-[1px] bg-black top-[-4px] left-[50%]"></div>
                      <div className="absolute h-[10px] w-[1px] bg-black top-[-4px] left-[75%]"></div>
                    </div>
                  </div>

                  {/* Right Label */}
                  <div className="font-bold text-xs">H</div>
                </div>
                <div className="w-full md:pl-10">
                  <div className="mt-2 sm:mt-2">
                    <h4 className="text-xs font-semibold uppercase text-gray-800 ">
                      {t("Summary")}
                    </h4>
                    <ul className="mt-3 flex flex-col">
                      <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg  ">
                        <div className="flex items-center justify-between w-full">
                          <span>{t("Time")}</span>
                          <span>
                            {loanPeriod} {t("Months")}
                          </span>
                        </div>
                      </li>
                      <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg ">
                        <div className="flex items-center justify-between w-full">
                          <span>Livestock Type</span>
                          <span>{livestock}</span>
                        </div>
                      </li>
                      <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-semibold bg-gray-50 border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg ">
                        <div className="flex items-center justify-between w-full">
                          <span>{t("Biodiversity")}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="green"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="lucide lucide-badge-check"
                          >
                            <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
                            <path d="m9 12 2 2 4-4" />
                          </svg>{" "}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* End Table */}

            <div className="grid grid-cols-1 gap-6 mb-16 ">
              <p className="text-center md:text-left font-semibold pt-3">
                {" "}
                {t("Climate Risk")}
              </p>
              <div className="md:flex p-4 items-center py-6 bg-[#F9F9F9]">
                <div className="w-100 md:w-[33%]">
                  <GaugePdf
                    level={
                      profitData?.total_scores?.climate_scores
                        ?.composite_climate_risk
                    }
                  />
                </div>
                <div className="w-100 md:w-[33%]">
                  <ProgressBar
                    piller={climate_indices[0]}
                    width={"100%"}
                    progress={
                      profitData?.total_scores?.climate_scores.drought_risk
                    }
                  />
                </div>
                <div className="w-100 md:w-[33%]">
                  <ProgressBar
                    width={"100%"}
                    piller={climate_indices[1]}
                    progress={
                      profitData?.total_scores?.climate_scores
                        ?.temperature_humidity_index_risk
                    }
                  />
                </div>
              </div>
              <p className="text-center md:text-left font-semibold pt-3">
                {t("Water Risk")}
              </p>
              <div className="md:flex p-4 py-6 items-center bg-[#F9F9F9]">
                <div className="w-100 md:w-[33%]">
                  <GaugePdf
                    level={
                      profitData?.total_scores?.water_scores
                        ?.composite_water_risk
                    }
                  />
                </div>
                <div className="w-100 md:w-[33%]">
                  <ProgressBar
                    piller={water_indices[0]}
                    width={"100%"}
                    progress={
                      profitData?.total_scores?.water_scores.water_stress_risk
                    }
                  />
                </div>
                <div className="w-100 md:w-[33%]">
                  <ProgressBar
                    piller={water_indices[1]}
                    width={"100%"}
                    progress={
                      profitData?.total_scores?.water_scores?.ground_water_risk
                    }
                  />
                </div>
              </div>
              <p className="text-center md:text-left font-semibold pt-3">
                {t("Profitability")}
              </p>
              <div className="md:flex p-4 py-6 items-center  bg-[#F9F9F9]">
                <div className="w-100 md:w-[33%]">
                  <GaugePdf
                    level={
                      profitData?.total_scores?.profitability_scores
                        .profitability_risk
                    }
                  />
                </div>
                <div className="w-100 md:w-[33%]">
                  <ProgressBar
                    piller={profitability_indices[0]}
                    width={"100%"}
                    progress={
                      profitData?.total_scores?.profitability_scores
                        .profitability_risk
                    }
                  />
                </div>
              </div>
              {/* <TopGauge
                pillar="CLIMATE"
                rainfall_risk={myRiskdata?.climate_scores.drought_risk}
                //@ts-ignore
                temperature_risk={myRiskdata?.climate_scores.precipitation_risk}
                //@ts-ignore
                drought_risk={myRiskdata?.climate_scores.temperature_risk}
                composite_climate_risk={
                  myRiskdata?.climate_scores.composite_climate_risk
                }
                categories={climate_indices}
              /> */}
              {/* <TopGauge
                pillar="WATER"
                rainfall_risk={myRiskdata?.water_scores.ground_water_risk}
                temperature_risk={
                  myRiskdata?.water_scores.rainfall_erosivity_risk
                }
                drought_risk={myRiskdata?.water_scores.location_aquaduct_risk}
                composite_climate_risk={
                  myRiskdata?.water_scores.composite_water_risk
                }
                categories={water_indices}
              /> */}
              {/* <TopGauge
                pillar="SOIL"
                rainfall_risk={myRiskdata?.soil_scores.soil_organic_carbon_risk}
                temperature_risk={myRiskdata?.soil_scores.soil_ph_risk}
                drought_risk={
                  myRiskdata?.soil_scores.cation_exchange_capacity_risk
                }
                composite_climate_risk={
                  myRiskdata?.soil_scores.composite_soil_risk
                }
                categories={soil_indices}
              /> */}
            </div>
            <div className="mt-5">
              <h4 className="text-2xl mb-2 font-semibold text-gray-800 ">
                {t("Climate Adaptation Suggestions To Improve Your Score")}
              </h4>
              {suggestionLoader ? (
                <>
                  <BeatLoader />
                </>
              ) : (
                <>
                  {Object.keys(translatedData).map((key) => {
                    return (
                      <div key={key}>
                        <h4 className="text-lg mb-2 font-semibold text-gray-800 ">
                          {key}
                        </h4>
                        {translatedData[key].map(({ Suggestion }) => {
                          return (
                            <div key={Suggestion}>
                              <p className="text-[#505050] text-sm font-normal mt-2">
                                {profitData?.total_scores?.adaptations[1] && (
                                  <>
                                    <li>{Suggestion}</li>
                                    <input
                                      type="text"
                                      placeholder="Enter your feedback"
                                      value={feedbacks[Suggestion] || ""}
                                      onChange={(e) =>
                                        handleInputChange(
                                          Suggestion,
                                          e.target.value
                                        )
                                      }
                                      className="w-full p-2 border border-gray-300 rounded-md mt-2"
                                    />
                                  </>
                                )}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </>
              )}

              <button
                onClick={(e) => handleSubmitSuggestion(e)}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
              >
                Submit Feedback
              </button>
            </div>
            <div className="mt-8">
              <h4 className="text-lg font-semibold text-gray-800 ">
                {t("Thank you!")}
              </h4>
              <p className="text-gray-500">
                {t(
                  "If you have any questions concerning this report, use the following contact information:"
                )}
              </p>
              <div className="mt-2">
                <p className="block text-sm font-medium text-gray-800 ">
                  info@adapta.earth
                </p>
              </div>
            </div>
            <p className="mt-5 text-sm text-gray-500">© 2023 ADAPTA.</p>
            <div className="mt-5 px-4 sm:px-6 lg:px-8">
              <p>{t("Add a comment")}</p>
              <textarea
                className=" mt-2 py-3 px-4 block w-full border-gray-200 rounded-md border text-sm focus:border-blue-500 focus:ring-blue-500  "
                rows={3}
                placeholder="Enter a comment ..."
                id="reviewer_comments"
                {...register("reviewer_comments")}
              />
            </div>

            {errors.reviewer_comments && (
              <div className="text-red-500">
                {errors.reviewer_comments.message}
              </div>
            )}
          </div>
          <div className="flex gap-2">
            <button
              type="submit"
              className=" mt-3 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm"
            >
              {t("SAVE")}
            </button>
            <button
              data-hs-overlay="#hs-bg-gray-on-hover-cards"
              className=" mt-3 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm "
            >
              {t("CLOSE")}
            </button>
          </div>
        </form>
      </div>
      {/* End Invoice */}
    </>
  );
};

export default PdfLivestock;
