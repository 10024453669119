import axiosClient from "axios";
import type { AxiosRequestConfig } from "axios";

import {
  API_BASE_URL_NEW,
  // FE_APP_ID,
  RISK_SCORING_SERVICE_BASE_URL,
  API_ECO_CROP_BASE_URL,
  API_RISK_UPDATE_BASE_URL,
  VITE_PROFITABLITY_BASE_URL,
} from "../constants";
import { msalInstance, acquireAccessToken } from "../authConfig";

const instance = axiosClient.create({
  baseURL: API_BASE_URL_NEW,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "x-adpata-application": "user",
    "x-client-identifier": "web",
  },
});

const instanceOid = axiosClient.create({
  baseURL: API_BASE_URL_NEW,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "x-adpata-application": "user",
    "x-client-identifier": "web",
  },
});
const instanceOidFormData = axiosClient.create({
  baseURL: API_BASE_URL_NEW,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    "x-adpata-application": "user",
    "x-client-identifier": "web",
  },
});
const instanceEco = axiosClient.create({
  baseURL: API_ECO_CROP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "x-adpata-application": "user",
    "x-client-identifier": "web",
  },
});
const instanceRiskUpate = axiosClient.create({
  baseURL: API_RISK_UPDATE_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "x-adpata-application": "user",
    "x-client-identifier": "web",
  },
});

const instanceRiskScore = axiosClient.create({
  baseURL: RISK_SCORING_SERVICE_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "x-adpata-application": "user",
    "x-client-identifier": "web",
  },
});
const instanceProfitability = axiosClient.create({
  baseURL: VITE_PROFITABLITY_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "x-adpata-application": "user",
    "x-client-identifier": "web",
  },
});

instanceOid.interceptors.request.use(
  async function (config) {
    //@ts-ignore
    // const this_oid = `${localStorage.getItem("tokenMsal")}`;
    // config.headers["oid"] = this_oid;

    try {
      console.log("token silent is running");
      const accToken = await acquireAccessToken(msalInstance);
      config.headers["Authorization"] = `Bearer ${accToken}`;
      return config;
    } catch (error) {
      sessionStorage.removeItem("msal.interaction.status"),
        // instance.logoutPopup({
        //   mainWindowRedirectUri: "/login", // redirects the top level app after logout
        // });
        msalInstance.logoutRedirect();
    }
  },
  function (error) {
    // Do something with request error
    console.log(error);
    return alert("Please login again");
  }
);
instanceOidFormData.interceptors.request.use(
  async function (config) {
    //@ts-ignore
    // const this_oid = `${localStorage.getItem("tokenMsal")}`;
    // config.headers["oid"] = this_oid;
    try {
      console.log("token silent is running");
      const accToken = await acquireAccessToken(msalInstance);
      config.headers["Authorization"] = `Bearer ${accToken}`;
      return config;
    } catch (error) {
      sessionStorage.removeItem("msal.interaction.status"),
        // instance.logoutPopup({
        //   mainWindowRedirectUri: "/login", // redirects the top level app after logout
        // });
        msalInstance.logoutRedirect();
    }
  },
  function (error) {
    // Do something with request error
    console.log(error);
    return alert("Please login again");
  }
);

instance.interceptors.request.use(
  async function (config) {
    //@ts-ignore
    // const this_oid = `${localStorage.getItem("tokenMsal")}`;
    // config.headers["oid"] = this_oid;
    try {
      console.log("token silent is running");
      const accToken = await acquireAccessToken(msalInstance);
      config.headers["Authorization"] = `Bearer ${accToken}`;
      return config;
    } catch (error) {
      sessionStorage.removeItem("msal.interaction.status"),
        // instance.logoutPopup({
        //   mainWindowRedirectUri: "/login", // redirects the top level app after logout
        // });
        msalInstance.logoutRedirect();
    }
  },
  function (error) {
    // Do something with request error
    console.log(error);
    return alert("Please login again");
  }
);

instanceRiskScore.interceptors.request.use(
  async function (config) {
    //@ts-ignore
    // const this_oid = `${localStorage.getItem("tokenMsal")}`;
    // config.headers["oid"] = this_oid;
    try {
      const accToken = await acquireAccessToken(msalInstance);
      config.headers["Authorization"] = `Bearer ${accToken}`;
      return config;
    } catch (error) {
      sessionStorage.removeItem("msal.interaction.status"),
        // instance.logoutPopup({
        //   mainWindowRedirectUri: "/login", // redirects the top level app after logout
        // });
        msalInstance.logoutRedirect();
    }
  },
  function (error) {
    // Do something with request error
    console.log(error);
    return alert("Please login again");
  }
);
instanceRiskUpate.interceptors.request.use(
  async function (config) {
    //@ts-ignore
    // const this_oid = `${localStorage.getItem("tokenMsal")}`;
    // config.headers["oid"] = this_oid;
    try {
      console.log("token silent is running");
      const accToken = await acquireAccessToken(msalInstance);
      config.headers["Authorization"] = `Bearer ${accToken}`;
      return config;
    } catch (error) {
      sessionStorage.removeItem("msal.interaction.status"),
        // instance.logoutPopup({
        //   mainWindowRedirectUri: "/login", // redirects the top level app after logout
        // });
        msalInstance.logoutRedirect();
    }
  },
  function (error) {
    // Do something with request error
    console.log(error);
    return alert("Please login again");
  }
);

instanceEco.interceptors.request.use(
  async function (config) {
    //@ts-ignore
    // const this_oid = `${localStorage.getItem("tokenMsal")}`;
    // config.headers["oid"] = this_oid;
    try {
      console.log("token silent is running");
      const accToken = await acquireAccessToken(msalInstance);
      config.headers["Authorization"] = `Bearer ${accToken}`;
      return config;
    } catch (error) {
      sessionStorage.removeItem("msal.interaction.status"),
        // instance.logoutPopup({
        //   mainWindowRedirectUri: "/login", // redirects the top level app after logout
        // });
        msalInstance.logoutRedirect();
    }
  },
  function (error) {
    // Do something with request error
    console.log(error);
    return alert("Please login again");
  }
);
instanceProfitability.interceptors.request.use(
  async function (config) {
    //@ts-ignore
    // const this_oid = `${localStorage.getItem("tokenMsal")}`;
    // config.headers["oid"] = this_oid;
    try {
      console.log("token silent is running");
      const accToken = await acquireAccessToken(msalInstance);
      config.headers["Authorization"] = `Bearer ${accToken}`;
      return config;
    } catch (error) {
      sessionStorage.removeItem("msal.interaction.status"),
        // instance.logoutPopup({
        //   mainWindowRedirectUri: "/login", // redirects the top level app after logout
        // });
        msalInstance.logoutRedirect();
    }
  },
  function (error) {
    // Do something with request error
    console.log(error);
    return alert("Please login again");
  }
);

const axiosPrivate = <T,>(cfg: AxiosRequestConfig) => {
  return instance.request<unknown, T>(cfg);
};
export const axiosProfitablity = <T,>(cfg: AxiosRequestConfig) => {
  return instanceProfitability.request<unknown, T>(cfg);
};

export const axiosPrivateOid = <T,>(cfg: AxiosRequestConfig) => {
  return instanceOid.request<unknown, T>(cfg);
};
export const axiosPrivateOidFormData = <T,>(cfg: AxiosRequestConfig) => {
  return instanceOidFormData.request<unknown, T>(cfg);
};
export const axiosEco = <T,>(cfg: AxiosRequestConfig) => {
  return instanceEco.request<unknown, T>(cfg);
};
export const axiosRiskUpdate = <T,>(cfg: AxiosRequestConfig) => {
  return instanceRiskUpate.request<unknown, T>(cfg);
};
export const axiosPrivateRiskScore = <T,>(cfg: AxiosRequestConfig) => {
  return instanceRiskScore.request<unknown, T>(cfg);
};

export default axiosPrivate;
