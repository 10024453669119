import { useTranslation } from "react-i18next";

export default function ClimateLivestock() {
  const { t } = useTranslation("help");
  return (
    <>
      {/* Card */}
      <div className="text-gray-800 body-font">
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          <strong>{t("Drought Score")}</strong>{" "}
        </h1>

        <p className="leading-relaxed text-base pb-3">
          {t(
            "It is a prolonged and recurring period of abnormally low precipitation (rainfall), which leads to water shortages in a specific region or area. It is a natural climatic phenomenon that occurs when there is insufficient rainfall or snowfall to meet the water needs of people, agriculture, and ecosystems."
          )}
        </p>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "Droughts can have significant adverse impacts on various aspects of society, including agriculture, livestock, water resources, ecosystems, and human well-being. Drought can significantly impact livestock production and health, creating challenges for farmers and ecosystems. These effects range from reduced feed and water availability to broader economic and environmental consequences."
          )}
        </p>
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            {t(
              "Drought reduces the growth of grasses and forage crops, leading to poor pasture quality and limited grazing options."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Rivers, ponds, and reservoirs may dry up, leaving livestock without adequate water supplies."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Poor-quality forage and feed shortages lead to nutrient deficiencies, reduced weight gain, and lower body condition scores."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Higher temperatures during drought increase heat stress in animals, reducing their productivity and fertility."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Drought creates favorable conditions for certain parasites and pathogens."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Prolonged droughts can lead to weakened animals and higher death rates, particularly among young and vulnerable stock."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Lactating animals produce less milk due to stress and poor nutrition."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Scarcity of forage pushes livestock to overgraze, causing soil erosion and loss of vegetation cover."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Increased competition for water and grazing areas can lead to tensions between farmers and communities."
            )}
          </li>
        </ul>
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("Strategies to mitigate drought effects on livestock")}
        </h1>
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            {t(
              "Grow drought-tolerant forage crops like sorghum, millet, or alfalfa."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Water management: Use efficient water delivery systems, like troughs with controlled flow rates, and build or improve water storage facilities (e.g., dams, tanks)."
            )}
          </li>
          <li className="mb-2">
            {t(
              "Adopt silvopastoral systems integrating trees with grazing to provide shade and additional forage."
            )}
          </li>
        </ul>

        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          <strong>{t("Temperature Humidity Score")}</strong>{" "}
        </h1>

        <p className="leading-relaxed text-base pb-3">
          {t(
            "Temperature is a measure of the heat energy in the atmosphere or a substance, commonly expressed in degrees Celsius (°C), Fahrenheit (°F), or Kelvin (K). It is a critical factor in determining climate, weather patterns, and biological processes."
          )}
        </p>
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("Effect of Temperature on Livestock")}
        </h1>
        <p className="leading-relaxed text-base pb-1">
          {t(
            "Temperature has a profound and multifaceted impact on livestock, influencing their health, behavior, reproduction, and productivity. Similar to humans, animals have a thermoneutral zone (TNZ)—a range of temperatures within which they can maintain a stable body temperature without expending extra energy. When temperatures deviate outside this zone, animals experience thermal stress, with both extreme heat and cold presenting unique challenges."
          )}
        </p>
        <p className="leading-relaxed text-base pb-1">
          {t(
            "Within livestock species, such as cattle, sheep, goats, pigs, and poultry, there are significant differences in how they cope with climatic conditions like temperature and humidity. Some animals are better adapted to extreme environments, while others are less suited to such conditions. These adaptations are the result of a combination of physiological and evolutionary strategies that have allowed different species to thrive in specific climates. For example, certain breeds of cattle have evolved to endure high temperatures by developing sweat glands or lighter coats, while others, like sheep, may have thicker wool to protect against cold conditions. Additionally, goats are known for their ability to survive in dry, arid environments due to their efficient water usage and resilient digestive systems. Pigs, on the other hand, are more sensitive to heat, often requiring cooler, shaded environments. Poultry, like chickens, have unique mechanisms to manage heat, such as panting and seeking shade. These diverse adaptive strategies allow livestock to inhabit a wide range of environments, with each species making use of its unique physiological traits to survive in the face of varying climatic challenges."
          )}
        </p>
        {/* <h1 className="pb-2 pt-2 text-lg font-medium text-gray-100 dark:text-white">
          {t("1. Heat Stress:")}
        </h1> */}
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            <strong>{t("Decreased Feed Intake:")}</strong>{" "}
            {t(
              "Higher temperatures can reduce an animal's appetite, leading to lower feed intake and potential weight loss."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Reduced Productivity:")}</strong>{" "}
            {t(
              "Heat stress can lead to significant drops in milk production for dairy cows. In meat-producing animals, it can slow growth rates."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Reproductive Issues:")}</strong>{" "}
            {t(
              "Heat stress can impair fertility, leading to lower conception rates and affecting the overall reproductive performance."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Health Problems:")}</strong>{" "}
            {t(
              "Heat stress increases susceptibility to diseases, dehydration, and, in severe cases, can lead to heatstroke and even death."
            )}
          </li>
        </ul>

        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("2. Cold Stress:")}
        </h1>
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            <strong>{t("Increased Energy Needs:")}</strong>{" "}
            {t(
              "In colder environments, animals require more energy to maintain their body temperature, often resulting in increased feed consumption."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Frostbite and Hypothermia: ")}</strong>{" "}
            {t(
              "Extreme cold can cause frostbite and hypothermia, especially in animals that lack proper shelter or sufficient body fat reserves."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Impaired Immune Function: ")}</strong>{" "}
            {t(
              "Cold stress can weaken an animal’s immune system, making them more vulnerable to respiratory and other diseases."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Reproductive Performance: ")}</strong>{" "}
            {t(
              "Similar to heat stress, cold stress can reduce reproductive efficiency, impacting libido and fertility in both males and females."
            )}
          </li>
        </ul>

        <p className="leading-relaxed text-base pb-3">
          {t(
            "Understanding and managing the effects of temperature on livestock is crucial for maintaining animal welfare, ensuring productivity, and achieving sustainable agricultural practices."
          )}
        </p>
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          <strong>{t("Temperature Humidity Index(THI)")}</strong>{" "}
        </h1>
        <p className="leading-relaxed text-base pb-3">
          <strong>{t("The Temperature Humidity Index (THI)")}</strong>{" "}
          {t(
            "is an integrated measure that combines air temperature and humidity to assess environmental stress on animals, particularly focusing on the conditions that may lead to heat stress. THI is critical in understanding how combined temperature and moisture levels in the air affect livestock health, comfort, and performance. Since animals rely on their surroundings to regulate body temperature, air temperature, and humidity are pivotal in influencing their thermal balance."
          )}
        </p>

        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("How THI Works")}
        </h1>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "The THI considers that as humidity increases, the effectiveness of heat dissipation through evaporation (such as sweating in humans or panting in animals) decreases. This reduced efficiency of body cooling mechanisms can lead to stress, even at moderate temperatures. The index is calculated using various formulae that input temperature and relative humidity data, producing a value that helps quantify the level of heat stress animals might experience."
          )}
        </p>

        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("Effects of THI on Livestock")}
        </h1>
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("1. Heat Stress:")}
        </h1>
        <ul className="list-disc list-inside">
          <li className="mb-2">
            <strong>{t("Reduced Feed Intake and Weight Gain:")}</strong>{" "}
            {t(
              "Elevated THI levels can decrease appetite and feed conversion efficiency, resulting in lower weight gain in meat-producing animals and reduced milk yield in dairy cattle."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Decreased Reproductive Efficiency:")}</strong>{" "}
            {t(
              "Heat stress affects both male and female reproduction, leading to lower fertility rates, increased embryonic mortality, and overall reduced reproductive performance."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Milk Production Decline:")}</strong>{" "}
            {t(
              "Dairy cows are particularly sensitive to heat stress, with high THI levels leading to a significant drop in milk production and changes in milk composition."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Health Issues:")}</strong>{" "}
            {t(
              "Increased THI values are associated with a higher incidence of health problems, including respiratory difficulties, heat exhaustion, and in severe cases, mortality. These health issues are due to the animals' struggle to dissipate body heat, leading to overheating and stress."
            )}
          </li>
        </ul>
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("2. Behavior Changes:")}
        </h1>
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            <strong>{t("Seeking Shade and Water:")}</strong>{" "}
            {t(
              "Animals naturally seek shade and water to cool down, leading to overcrowding in certain areas and increased competition for resources."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Altered Feeding Patterns:")}</strong>{" "}
            {t(
              "Livestock may alter their feeding behavior, feeding more during cooler parts of the day and less during peak heat, affecting their overall nutrient intake and health"
            )}
          </li>
        </ul>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "By closely monitoring THI and implementing appropriate management strategies, livestock producers can significantly reduce the adverse effects of heat stress, thereby improving animal welfare, productivity, and overall farm profitability."
          )}
        </p>
      </div>

      {/* End Card */}
    </>
  );
}
