import { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import {
  Users,
  CreditCard,
  TrendingUp,
  FileText,
  ChevronDown,
  ChevronUp,
  Download,
} from "lucide-react";
import MainDashboard from "../../components/dashboards/main_dashboard";

const userStats = [
  { name: "Total Users", value: 10000, icon: Users, color: "bg-blue-500" },
  {
    name: "Active Subscriptions",
    value: 8500,
    icon: CreditCard,
    color: "bg-green-500",
  },
  {
    name: "New Users (This Month)",
    value: 1200,
    icon: TrendingUp,
    color: "bg-yellow-500",
  },
  {
    name: "Reports Generated",
    value: 5000,
    icon: FileText,
    color: "bg-purple-500",
  },
];

const creditUsageData = [
  { name: "Jan", usage: 4000 },
  { name: "Feb", usage: 3000 },
  { name: "Mar", usage: 2000 },
  { name: "Apr", usage: 2780 },
  { name: "May", usage: 1890 },
  { name: "Jun", usage: 2390 },
];

const subscriptionData = [
  { name: "Basic", value: 3000 },
  { name: "Pro", value: 4500 },
  { name: "Enterprise", value: 1000 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

const reports = [
  { id: 1, name: "Monthly User Activity", date: "2023-06-01", downloads: 250 },
  { id: 2, name: "Subscription Revenue", date: "2023-06-01", downloads: 180 },
  { id: 3, name: "Credit Usage Analysis", date: "2023-06-01", downloads: 320 },
  { id: 4, name: "User Retention Report", date: "2023-05-01", downloads: 150 },
  {
    id: 5,
    name: "Feature Adoption Metrics",
    date: "2023-05-01",
    downloads: 200,
  },
];

export default function AdminHome() {
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  });

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const sortedReports = [...reports].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key])
      return sortConfig.direction === "asc" ? -1 : 1;
    if (a[sortConfig.key] > b[sortConfig.key])
      return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  return (
    <MainDashboard>
      <div className="min-h-screen bg-gray-100 p-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-800 mb-8">
            Admin Dashboard
          </h1>

          {/* User Stats */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            {userStats.map((stat) => (
              <div key={stat.name} className="bg-white rounded-lg shadow p-6">
                <div className="flex items-center">
                  <div className={`${stat.color} rounded-full p-3 mr-4`}>
                    <stat.icon className="w-6 h-6 text-white" />
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">
                      {stat.name}
                    </p>
                    <p className="text-2xl font-semibold text-gray-800">
                      {stat.value.toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Credit Usage */}
          <div className="bg-white rounded-lg shadow mb-8">
            <div className="p-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                Credit Usage
              </h2>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={creditUsageData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="usage" fill="#4F46E5" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          {/* Subscription Performance */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                Subscription Performance
              </h2>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={subscriptionData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      label={({ name, percent }) =>
                        `${name} ${(percent * 100).toFixed(0)}%`
                      }
                    >
                      {subscriptionData.map((index) => (
                        <Cell
                          key={`cell-${index}`}
                          //@ts-ignore
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                Subscription Breakdown
              </h2>
              <div className="space-y-4">
                {subscriptionData.map((sub, index) => (
                  <div
                    key={sub.name}
                    className="flex items-center justify-between"
                  >
                    <div className="flex items-center">
                      <div
                        className={`w-4 h-4 rounded-full mr-3`}
                        style={{ backgroundColor: COLORS[index] }}
                      ></div>
                      <span className="text-gray-700">{sub.name}</span>
                    </div>
                    <span className="font-semibold text-gray-900">
                      {sub.value.toLocaleString()} users
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Reports Management */}
          <div className="bg-white rounded-lg shadow">
            <div className="p-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                Reports Management
              </h2>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <button
                          className="flex items-center focus:outline-none"
                          onClick={() => handleSort("name")}
                        >
                          Report Name
                          {sortConfig.key === "name" &&
                            (sortConfig.direction === "asc" ? (
                              <ChevronUp size={16} />
                            ) : (
                              <ChevronDown size={16} />
                            ))}
                        </button>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <button
                          className="flex items-center focus:outline-none"
                          onClick={() => handleSort("date")}
                        >
                          Date
                          {sortConfig.key === "date" &&
                            (sortConfig.direction === "asc" ? (
                              <ChevronUp size={16} />
                            ) : (
                              <ChevronDown size={16} />
                            ))}
                        </button>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <button
                          className="flex items-center focus:outline-none"
                          onClick={() => handleSort("downloads")}
                        >
                          Downloads
                          {sortConfig.key === "downloads" &&
                            (sortConfig.direction === "asc" ? (
                              <ChevronUp size={16} />
                            ) : (
                              <ChevronDown size={16} />
                            ))}
                        </button>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {sortedReports.map((report) => (
                      <tr key={report.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {report.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {report.date}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {report.downloads}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                          <button
                            className="text-indigo-600 hover:text-indigo-900 flex items-center focus:outline-none"
                            onClick={() =>
                              console.log(`Download report ${report.id}`)
                            }
                          >
                            Download
                            <Download size={16} className="ml-2" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainDashboard>
  );
}
