//@ts-ignore
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { axiosPrivateRiskScore } from "../api/axiosPrivate";
import Swal from "sweetalert2";
import { PhoneInput } from "react-international-phone";
import ProgressBar from "./ProgressBar";
import GaugePdf from "./GaugePdf";
import { MapForReport } from "./PlacesAutocomplete";
import { useTranslation } from "react-i18next";
import "react-international-phone/style.css";
import { useEffect, useState } from "react";
import { useTranslateNested } from "../hooks/useAuth";
import BeatLoader from "react-spinners/BeatLoader";

interface RiskDataProps {
  myRiskdata: any;
  loanPeriod: string;
  crop: string;
  myLocation: {
    lat: number;
    lng: number;
  };
  locationScoreId: string;
  suggestionLoader:boolean;
}

interface FormData {
  debtor_name: string;
  debtor_contacts: string;
  reviewer_comments: string;
  location_score_id: string;
  saved: boolean;
  location_name: string;
 
}

const validationSchema = yup.object({
  debtor_name: yup.string().required("Debtor name is required"),
  debtor_contacts: yup.string(),
  reviewer_comments: yup.string().required("Reviewer comments are required"),
  location_score_id: yup.string(),
  location_name: yup.string(),
});

const PdfComponent = ({
  myRiskdata,
  loanPeriod,
  crop,
  myLocation,
  locationScoreId,
  suggestionLoader
}: RiskDataProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: yupResolver(validationSchema) as any,
  });
  const [data, setData] = useState({});
  const { t } = useTranslation("home");
  const [phone, setPhone] = useState(null);

  //@ts-ignore
  const [ratedSuggestions, setRatedSuggestions] = useState<RatedSuggestion[]>(
    []
  );
  const [countryCode, setCountryCode] = useState("ke");
  const translatedData = useTranslateNested(data, localStorage.getItem("lang"));
  console.log(translatedData, "trans");
  const climate_indices = [t("Drought"), t("Precipitation"), t("Temperature")];
  const water_indices = [
    t("Groundwater"),
    t("Water Erosion"),
    t("Water Stress"),
  ];
  const soil_indices = [
    t("Top Soil Fertility"),
    t("Soil pH"),
    t("Nutrient capacity"),
  ];
  // const oid = localStorage.getItem("oid");
  // const PROFILE_URL = `/risk/v1/location_scores/${locationScoreId}/update`;
  const PROFILE_URL = `/v1/location_scores/${locationScoreId}/update`;
  const FEEDBACK_URL = "/v1/suggestions/crop/feedback/insert";

  const [feedbacks, setFeedbacks] = useState<{ [key: string]: string }>({});

  const handleInputChange = (suggestion: string, value: string) => {
    setFeedbacks((prev) => ({ ...prev, [suggestion]: value }));
  };

  const handleSubmitSuggestion = (e) => {
    e.preventDefault();
    const suggestion_feedbacks: any[] = Object.entries(feedbacks).map(
      ([suggestion, revision]) => ({
        revision,
        suggestion,
      })
    );
    console.log(suggestion_feedbacks, "apit");

    const data = {
      report_id: locationScoreId,
      suggestion_feedbacks: suggestion_feedbacks,
    };

    axiosPrivateRiskScore({
      method: "POST",
      url: FEEDBACK_URL,
      data: JSON.stringify(data),
    })
      .then((data) => {
        // setUser(data);
        console.log(JSON.stringify(data));
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: t("Feedback send successfully"),
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.error("API Error:", error);
        Swal.fire({
          icon: "error",
          title: t("Oops..."),
          text: t("Something went wrong!"),
        });
      });
    // Here you would send the data to your API
  };
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    // data.location_score_id = locationScoreId;
    delete data.location_score_id;
    data.saved = true;
    data.debtor_contacts = phone;
    axiosPrivateRiskScore({
      method: "PUT",
      url: PROFILE_URL,
      data: JSON.stringify(data),
    })
      .then((data) => {
        // setUser(data);
        console.log(JSON.stringify(data));
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: t("Comment Submitted"),
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        console.error("API Error:", error);
        Swal.fire({
          icon: "error",
          title: t("Oops..."),
          text: t("Something went wrong!"),
        });
      });
  };
  useEffect(() => {
    let temp1 = myRiskdata?.adaptations ?? {};
    let newData = {}; // Temporary object to hold data

    Object.keys(temp1).forEach((key) => {
      if (Object.keys(newData).includes(temp1[key]?.Pillar)) {
        newData = {
          ...newData,
          [temp1[key]?.Pillar]: [...newData?.[temp1[key]?.Pillar], temp1[key]],
        };
      } else {
        newData[temp1[key]?.Pillar] = [temp1[key]];
      }
    });

    // Update the state with the new data
    setData(newData);
  }, [myRiskdata]);

  const handlePhoneChange = (value, country) => {
    setPhone(value);
    console.log("xu", value);

    setCountryCode(country?.country?.iso2); // Save the ISO country code (e.g., 'us')
  };

  useEffect(() => {
    setCountryCode("ke");
  }, []);

  return (
    <>
      {/* Invoice */}
      <div id="printablediv" className="max-w-[85rem]  mx-auto ">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Grid */}
          <div className="flex justify-center items-center  border-gray-200 ">
            <div>
              <img alt="Logo" className="w-24 mx-auto" src="/img/logo.png" />
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-x-2  border-b border-gray-200  p-4 pr-5">
            <div>
              <div className="grid space-y-3  ">
                <div className="grid sm:flex flex-col gap-x-3 ">
                  <div className="min-w-[105px] max-w-[200px] text-gray-500 pb-2">
                    {t("Full Name")}:
                  </div>
                  <div className="max-w-[100%]  text-gray-800 dark:text-gray-200">
                    <input
                      type="text"
                      id="debtor_name"
                      className="min-w-[100%] rounded-sm border-gray-200  "
                      placeholder={t("Full Name")}
                      {...register("debtor_name")}
                    />
                    {errors.debtor_name && (
                      <div className="text-red-500">
                        {errors.debtor_name.message}
                      </div>
                    )}
                  </div>
                </div>
                <dl className="grid sm:flex flex-col gap-x-3 text-sm">
                  <dt className="min-w-[100%] pb-[-6px] max-w-[200px] text-gray-500">
                    {t("Phone Number")}:
                  </dt>
                  <dd className="font-medium text-gray-800 relative ">
                    <Controller
                      control={control}
                      name="debtor_contacts"
                      render={({ field: {} }) => (
                        <PhoneInput
                          className="w-full border-gray-200"
                          defaultCountry="ke"
                          inputClassName="w-full border-gray-200"
                          name="phone"
                          onChange={handlePhoneChange}
                        />
                      )}
                    />
                    {/* {errors.debtor_contacts && (
                      <div className="text-red-500">
                        {errors.debtor_contacts.message}
                      </div>
                    )} */}

                    {true && (
                      <div
                        className="absolute top-[17px] bottom-1/2 left-[2.6px]"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={`https://flagcdn.com/w20/${countryCode}.png`}
                          alt="Flag"
                          style={{
                            width: "24px",
                            height: "15px",
                            marginRight: "10px",
                          }}
                        />
                      </div>
                    )}
                  </dd>
                </dl>
              </div>
            </div>
            {/* Col */}
            <div>
              <div className="grid space-y-3 mt-[3px]">
                <dl className="grid sm:flex  flex-col gap-x-3 text-sm">
                  <dt className="min-w-[150px] max-w-[200px] pb-2 text-gray-500">
                    {t("Location(Town)")}:
                  </dt>
                  <dd className="min-w-[100%] font-medium text-gray-800 ">
                    <input
                      type="text"
                      {...register("location_name")}
                      id="input-label"
                      className=" min-w-[100%] rounded-sm border-gray-200     "
                      placeholder={t("Location")}
                    />
                    <address className="not-italic font-normal">
                      {`LatLng(${myLocation?.lat || "Latitude"}, ${
                        myLocation?.lng || "Longitude"
                      })`}
                    </address>
                  </dd>
                </dl>
              </div>
            </div>
            {/* Col */}
          </div>
          {/* End Grid */}
          <div className="pt-2 px-4 sm:px-6 lg:px-8">
            {/* Grid */}

            {/* End Grid */}

            <div className="">
              <h4 className="text-xl pb-2  font-semibold  text-gray-800 ">
                {t("Detailed Risk Report Analysis")}
              </h4>
            </div>
            {/* Table */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 border border-gray-200 p-4 rounded-lg  ">
              <div className="flex flex-col items-center h-[300px] ">
                <h3 className="mb-3 font-semibold text-gray-800  "></h3>

                {/* <MapWithMarker
                  markerPosition={[
                    myLocation?.lat || -0.3615164,
                    myLocation?.lng || 35.3084548,
                  ]}
                /> */}
                <MapForReport lat={myLocation?.lat} lng={myLocation?.lng} />
              </div>
              <div className="flex flex-col justify-between  items-center ">
                <h3 className="mb-1  font-semibold text-gray-800  ">
                  {t("Composite Risk Score")}
                </h3>
                {/* <Gauge level={ /> */}
                <div>
                  <ProgressBar
                    //@ts-ignore
                    isBig={true}
                    progress={myRiskdata?.composite_total_risk}
                    width={300}
                  />
                  <div className="flex items-center justify-between w-full  relative">
                    {/* Left Label */}
                    <div className="font-bold text-xs text-black">L</div>

                    {/* Line with Divisions */}
                    <div className="relative flex-grow mx-2">
                      {/* Main Line */}
                      <div className="h-[1px] bg-black w-full relative">
                        {/* Division Marks */}
                        <div className="absolute h-[10px] w-[1px] bg-black top-[-4px] left-[25%]"></div>
                        <div className="absolute h-[10px] w-[1px] bg-black top-[-4px] left-[50%]"></div>
                        <div className="absolute h-[10px] w-[1px] bg-black top-[-4px] left-[75%]"></div>
                      </div>
                    </div>

                    {/* Right Label */}
                    <div className="font-bold text-xs text-black">H</div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="">
                    <h4 className="text-xs font-semibold uppercase text-gray-800 ">
                      {t("Summary")}
                    </h4>
                    <ul className="mt-3 flex flex-col bg-[#FAFAFA]">
                      <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm  text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg  ">
                        <div className="flex items-center justify-between w-full">
                          <span>{t("Time")}</span>
                          <span>
                            {loanPeriod} {t("Months")}
                          </span>
                        </div>
                      </li>
                      <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm  text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg ">
                        <div className="flex items-center justify-between w-full">
                          <span>{t("Crop")}</span>
                          <span>{crop}</span>
                        </div>
                      </li>
                      <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-semibold bg-gray-50  text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg ">
                        <div className="flex items-center justify-between gap-x-2 w-full">
                          <span>{t("Biodiversity")}</span>
                          <i className="bi bi-patch-check-fill text-lg text-green-500"></i>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* End Table */}
            <div className="grid grid-cols-1 gap-6 mb-16 ">
              <p className="text-center md:text-left font-semibold pt-3">
                {t("Climate Risk")}
              </p>
              <div className="md:flex p-4 items-center  bg-[#F9F9F9]">
                <div className="w-100 md:w-[25%] mb-6 md:md-0">
                  <GaugePdf
                    level={myRiskdata?.climate_scores.composite_climate_risk}
                  />
                </div>
                <div className="w-100 md:w-[25%]">
                  <ProgressBar
                    piller={climate_indices[0]}
                    width={"100%"}
                    progress={myRiskdata?.climate_scores.drought_risk}
                  />
                </div>{" "}
                <div className="w-100 md:w-[25%]">
                  <ProgressBar
                    width={"100%"}
                    piller={climate_indices[1]}
                    progress={myRiskdata?.climate_scores.precipitation_risk}
                  />
                </div>{" "}
                <div className="w-100 md:w-[25%]">
                  <ProgressBar
                    piller={climate_indices[2]}
                    width={"100%"}
                    progress={myRiskdata?.climate_scores.temperature_risk}
                  />
                </div>
              </div>
              <p className="text-center md:text-left font-semibold pt-3">
                {t("Water Risk")}
              </p>
              <div className="md:flex p-4 items-center  bg-[#F9F9F9]">
                <div className="w-100 md:w-[25%]">
                  <GaugePdf
                    level={myRiskdata?.water_scores.composite_water_risk}
                  />
                </div>
                <div className="w-100 md:w-[25%]">
                  <ProgressBar
                    piller={water_indices[0]}
                    width={"100%"}
                    progress={myRiskdata?.water_scores.ground_water_risk}
                  />
                </div>
                <div className="w-100 md:w-[25%]">
                  <ProgressBar
                    piller={water_indices[1]}
                    width={"100%"}
                    progress={myRiskdata?.water_scores.rainfall_erosivity_risk}
                  />
                </div>
                <div className="w-100 md:w-[25%]">
                  <ProgressBar
                    piller={water_indices[2]}
                    width={"100%"}
                    progress={myRiskdata?.water_scores.location_aquaduct_risk}
                  />
                </div>
              </div>
              <p className="text-center md:text-left font-semibold pt-3">
                {t("Soil Risk")}
              </p>
              <div className="md:flex p-4 items-center  bg-[#F9F9F9]">
                <div className="w-100 md:w-[25%]">
                  <GaugePdf
                    level={myRiskdata?.soil_scores.composite_soil_risk}
                  />
                </div>
                <div className="w-100 md:w-[25%]">
                  <ProgressBar
                    piller={soil_indices[0]}
                    width={"100%"}
                    progress={myRiskdata?.soil_scores.soil_organic_carbon_risk}
                  />
                </div>
                <div className="w-100 md:w-[25%]">
                  <ProgressBar
                    piller={soil_indices[1]}
                    width={"100%"}
                    progress={myRiskdata?.soil_scores.soil_ph_risk}
                  />
                </div>
                <div className="w-100 md:w-[25%]">
                  <ProgressBar  
                    piller={soil_indices[2]}
                    width={"100%"}
                    progress={
                      myRiskdata?.soil_scores.cation_exchange_capacity_risk
                    }
                  />
                </div>
              </div>
              {/* <TopGauge
                pillar="CLIMATE"
                rainfall_risk={myRiskdata?.climate_scores.drought_risk}
                //@ts-ignore
                temperature_risk={myRiskdata?.climate_scores.precipitation_risk}
                //@ts-ignore
                drought_risk={myRiskdata?.climate_scores.temperature_risk}
                composite_climate_risk={
                  myRiskdata?.climate_scores.composite_climate_risk
                }
                categories={climate_indices}
              /> */}
              {/* <TopGauge
                pillar="WATER"
                rainfall_risk={myRiskdata?.water_scores.ground_water_risk}
                temperature_risk={
                  myRiskdata?.water_scores.rainfall_erosivity_risk
                }
                drought_risk={myRiskdata?.water_scores.location_aquaduct_risk}
                composite_climate_risk={
                  myRiskdata?.water_scores.composite_water_risk
                }
                categories={water_indices}
              /> */}
              {/* <TopGauge
                pillar="SOIL"
                rainfall_risk={myRiskdata?.soil_scores.soil_organic_carbon_risk}
                temperature_risk={myRiskdata?.soil_scores.soil_ph_risk}
                drought_risk={
                  myRiskdata?.soil_scores.cation_exchange_capacity_risk
                }
                composite_climate_risk={
                  myRiskdata?.soil_scores.composite_soil_risk
                }
                categories={soil_indices}
              /> */}
            </div>
            <div className="mt-5">
              <h4 className="text-2xl mb-2 font-semibold text-gray-800 ">
                {t("Climate Adaptation Suggestions To Improve Your Score")}
              </h4>
            {
              suggestionLoader ? (<>
                       <BeatLoader/>
              </>) : (<>
              {Object.keys(translatedData).map((key) => (
                <div key={key}>
                  <h4 className="text-lg mb-2 font-semibold text-gray-800">
                    {key}
                  </h4>
                  {translatedData[key].map(({ Suggestion }) => (
                    <div key={Suggestion}>
                      <p className="text-[#505050] text-sm font-normal mt-2">
                        {myRiskdata?.adaptations[1] && (
                          <>
                            <li>{Suggestion}</li>
                            <div className="flex space-x-2">
                              <input
                                type="text"
                                placeholder="Enter your feedback"
                                value={feedbacks[Suggestion] || ""}
                                onChange={(e) =>
                                  handleInputChange(Suggestion, e.target.value)
                                }
                                className="w-full p-2 border border-gray-300 rounded-md mt-2"
                              />
                            </div>
                          </>
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              ))}
              <button
                onClick={(e) => handleSubmitSuggestion(e)}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
              >
                Submit Feedback
              </button>
              {/* <h4 className="text-lg mb-2 font-semibold text-gray-800 dark:text-gray-200">
                {myRiskdata?.adaptations[1]?.Pillar}
              </h4>
              <p className="text-gray-500 mt-2">
                {myRiskdata?.adaptations[1] && (
                  <li>{myRiskdata?.adaptations[1]?.Suggestion}</li>
                )}
              </p>
              <p className="text-gray-500 mt-2">
                {myRiskdata?.adaptations[2] && (
                  <li>{myRiskdata?.adaptations[2]?.Suggestion}</li>
                )}
              </p>
              <h4 className="text-lg mb-2 font-semibold text-gray-800 dark:text-gray-200 mt-2">
                {myRiskdata?.adaptations[3]?.Pillar}
              </h4>
              <p className="text-gray-500 mt-2">
                {myRiskdata?.adaptations[3] && (
                  <li>{myRiskdata?.adaptations[3]?.Suggestion}</li>
                )}
              </p>
              <p className="text-gray-500 mt-2">
                {myRiskdata?.adaptations[4] && (
                  <li>{myRiskdata?.adaptations[4]?.Suggestion}</li>
                )}
              </p>
              <h4 className="text-lg mb-2 font-semibold text-gray-800 dark:text-gray-200 mt-2">
                {myRiskdata?.adaptations[5]?.Pillar}
              </h4>
              <p className="text-gray-500 mt-2">
                {myRiskdata?.adaptations[5] && (
                  <li>{myRiskdata?.adaptations[5]?.Suggestion}</li>
                )}
              </p>
              <p className="text-gray-500 mt-2">
                <p className="text-gray-500 mt-2">
                  {myRiskdata?.adaptations[6] && (
                    <li>{myRiskdata?.adaptations[6]?.Suggestion}</li>
                  )}
                </p>
              </p> */}
              </>) 
            }

            </div>
            <div className="mt-8">
              <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                {t("Thank you!")}
              </h4>
              <p className="text-gray-500">
                {t(
                  "If you have any questions concerning this report, use the following contact information:"
                )}
              </p>
              <div className="mt-2">
                <p className="block text-sm font-medium text-gray-800 ">
                  info@adapta.earth
                </p>
              </div>
            </div>
            <p className="mt-5 text-sm text-gray-500">© 2023 ADAPTA.</p>

            <div className="mt-5 px-4 sm:px-6 lg:px-8">
              <p>{t("Add a comment")}</p>
              <textarea
                className=" mt-2 py-3 px-4 block w-full border-gray-200 rounded-md border text-sm focus:border-blue-500 focus:ring-blue-500 "
                rows={3}
                placeholder="Enter a comment ..."
                id="reviewer_comments"
                {...register("reviewer_comments")}
              />
            </div>
            {errors.reviewer_comments && (
              <div className="text-red-500">
                {errors.reviewer_comments.message}
              </div>
            )}
          </div>

          <div className="flex gap-x-2">
            <button
              type="submit"
              className=" mt-3 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm "
            >
              {t("SAVE")}
            </button>
            <button
              data-hs-overlay="#hs-bg-gray-on-hover-cards"
              onClick={() => {}}
              className=" mt-3 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm "
            >
              {t("CLOSE")}
            </button>
          </div>
        </form>
      </div>
      {/* End Invoice */}
    </>
  );
};

export default PdfComponent;
